<template>
  <div>
    <div
      v-if="loading"
      v-loading="true"
      style="height: 300px;"
    />
    <el-form
      v-else
      ref="form"
      label-width="150px"
      :model="model"
      :rules="rules"
    >
      <el-form-item label="Период">
        <date-select
          v-model="currentDate"
          lock-max
        />
      </el-form-item>
      <el-form-item
        label="Проект"
        prop="projectIds"
      >
        <el-select
          v-model="projectIds"
          multiple
          filterable
        >
          <el-option
            v-for="item in availableProjects"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Исполнитель">
        <el-select
          v-model="workerId"
          clearable
          filterable
        >
          <el-option
            v-for="item in filteredWorkers"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-checkbox
          v-model="splitWorkers"
          true-label="1"
          false-label="0"
        >
          Каждый исполнитель на отдельном листе
        </el-checkbox>
      </el-form-item>
      <el-form-item>
        <el-button
          type="success"
          :disabled="projectIds.length === 0"
          @click="download"
        >
          Скачать
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import ReportFormMixin from '@/mixins/ReportFormMixin'
import api from '@/api/api'
import { mapGetters } from 'vuex'

export default {
  name: 'PageReportDownload',
  mixins: [ReportFormMixin],
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rules: {
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['token']),
    model () {
      return this.$route.query
    },
    workerId: {
      set (worker) {
        this.go({ worker })
      },
      get () {
        return this.$route.query.worker ? parseInt(this.$route.query.worker.toString()) : null
      }
    },
    projectIds: {
      set (projects) {
        if (
          projects &&
          this.workerId &&
          this.availableProjects.find(item => projects.includes(item.id) && item.workers.includes(this.workerId)) === undefined
        ) {
          this.go({ projects: projects.join(','), worker: '' })
        } else {
          this.go({ projects: projects.join(',') })
        }
      },
      get () {
        return this.$route.query.projects ? this.$route.query.projects.toString().split(',').map(item => parseInt(item)) : []
      }
    },
    splitWorkers: {
      set (splitWorkers) {
        this.go({ splitWorkers })
      },
      get () {
        return this.$route.query.splitWorkers === '1'
      }
    },
    projects () {
      return this.availableProjects.filter(project => this.projectIds.includes(project.id))
    },
    filteredWorkers () {
      return this.projects ? this.userItems.filter(user => this.projects.find(project => project.workers.includes(user.id)) !== undefined) : this.availableProjects
    }
  },
  methods: {
    download () {
      this.$refs.form.validate(valid => {
        if (valid) {
          window.open(api.domain + '/download/export/report?' + (new URLSearchParams({ ...this.$route.query, 'access-token': this.token })).toString())
        }
      })
    }
  }
}
</script>
