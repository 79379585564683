import axios from 'axios'
import store from '@/store'
import router from '@/router'

const domain = process.env.NODE_ENV === 'production'
  ? ''
  : 'http://timing.dev.novene.ru' // 'http://worktime.localhost'

const instance = axios.create({
  baseURL: domain + '/api/v1/',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

const request = (type, method, config, showError) => new Promise((resolve, reject) => {
  instance[type](method, config).then(response => {
    if (response.data.success) {
      resolve(response.data.data)
    } else {
      switch (response.data.data.status) {
        case 400:
          reject(response.data.data.errors)
          break
        case 401:
          store.commit('auth/clearAuthData')
          break
        case 404:
        case 403:
          if (showError) {
            router.replace('/' + response.data.data.status)
          } else {
            reject(response.data.data)
          }
          break
        default:
          reject(response.data.data)
      }
    }
  }).catch(() => {
    reject(new Error('Ошибка подключения к серверу'))
  })
})

export default {
  instance,
  request,
  domain,
  get: (method, config, showError = false) => request('get', method, config, showError),
  post: (method, config) => request('post', method, config, false)
}
