<template>
  <el-main>
    <el-tabs v-model="activeTab">
      <el-tab-pane
        label="Просмотр"
        name="#view"
      >
        <page-report-view ref="view" />
      </el-tab-pane>
      <el-tab-pane
        label="Скачать"
        name="#download"
      >
        <page-report-download :loading="loading" />
      </el-tab-pane>
    </el-tabs>
  </el-main>
</template>
<script>
import PageReportView from '@/components/pages/PageReportView'
import PageReportDownload from '@/components/pages/PageReportDownload'
import { mapActions } from 'vuex'
export default {
  name: 'PageReport',
  components: { PageReportDownload, PageReportView },
  data () {
    return {
      loading: true
    }
  },
  computed: {
    activeTab: {
      get () {
        return this.$route.hash === '#download' ? this.$route.hash : '#view'
      },
      set (tab) {
        if (tab !== this.$route.hash) {
          this.$router.push({ query: this.$route.query, hash: tab })
        }
      }
    }
  },
  mounted () {
    this.loadUsers().then(() => {
      this.loadProjects().then(() => {
        this.loading = false
        this.$refs.view.load()
      })
    })
  },
  methods: {
    ...mapActions('users', ['loadUsers']),
    ...mapActions('projects', ['loadProjects'])
  }
}
</script>
