<template>
  <div>
    <block-comments-item
      v-for="item in items"
      :key="item.id"
      :item="item"
      @edit="v => { $emit('edit', v) }"
    />
  </div>
</template>
<script>

import BlockCommentsItem from '@/components/blocks/BlockCommentsItem'

export default {
  name: 'BlockComments',
  components: {
    BlockCommentsItem
  },
  props: {
    items: { type: Array, required: true }
  }
}

</script>
