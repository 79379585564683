<template>
  <el-main>
    <div v-if="loading">
      Загрузка...
    </div>
    <template v-else>
      <widget-worktime
        :users="userItems"
        :user-id="userData.id"
        :role="role"
      />

      <widget-history
        :role="role"
      />
    </template>
  </el-main>
</template>
<script>

import WidgetWorktime from '../widgets/WidgetWorktime'
import { mapActions, mapGetters } from 'vuex'
import WidgetHistory from '@/components/widgets/WidgetHistory'

export default {
  name: 'PageDashboard',
  components: {
    WidgetHistory,
    WidgetWorktime
  },
  data () {
    return {
      loading: true
    }
  },
  computed: {
    ...mapGetters('auth', ['userData', 'role']),
    ...mapGetters('users', ['userItems'])
  },
  mounted () {
    this.loadUsers().finally(() => {
      this.loading = false
    })
  },
  methods: {
    ...mapActions('users', ['loadUsers'])
  }
}
</script>
