<template>
  <block-item-wrapper
    :id="elementId"
    :highlight="$route.hash === '#' + elementId"
  >
    <div class="title">
      <el-link :href="'#' + elementId">
        <el-icon name="time" />
        <span
          v-if="item.minutes > 0"
          style="margin-right: 10px;"
        >
          {{ item.minutes | toHours }}
        </span>
        <span>{{ item.user_id | userNameById(userItems) }}</span>
        <span
          v-if="dateEqual(item.day, item.updated_at)"
          class="small"
          style="margin-left: 10px;"
        >
          {{ item.updated_at | formatDateTime }}
        </span>
        <span
          v-else
          class="small"
          style="margin-left: 10px;"
        >
          {{ item.day | formatDate }} ({{ item.updated_at | formatDateTime }})
        </span>
      </el-link>
      <el-link
        v-if="canEdit && item.minutes"
        type="primary"
        icon="el-icon-edit"
        size="mini"
        @click="$emit('edit', item)"
      >
        Редактировать
      </el-link>
      <el-popconfirm
        v-if="canEdit && item.minutes"
        confirm-button-text="Да"
        cancel-button-text="Нет"
        title="Удалить эту запись о работе?"
        @confirm="$emit('delete', item)"
      >
        <el-link
          slot="reference"
          type="danger"
          icon="el-icon-delete"
          size="mini"
        >
          Удалить
        </el-link>
      </el-popconfirm>
    </div>
    <custom-editor-view :value="item.description" />
  </block-item-wrapper>
</template>
<script>

import { userNameById, toHours, formatDateTime, formatDate } from '@/filters'
import { mapGetters } from 'vuex'
import BlockItemWrapper from '@/components/blocks/BlockItemWrapper'
import { checkDateCondition } from '@/utils/funcs'

export default {
  name: 'BlockWorktimeItem',
  components: { BlockItemWrapper },
  filters: {
    userNameById,
    toHours,
    formatDateTime,
    formatDate
  },
  props: {
    isManager: { type: Boolean, required: true },
    item: { type: Object, required: true }
  },
  computed: {
    ...mapGetters('users', ['userItems']),
    ...mapGetters('auth', ['userData']),
    canEdit () {
      return this.isManager || (this.userData.id === this.item.user_id && checkDateCondition(this.item.created_at))
    },
    elementId () {
      return `worktime-${this.item.id}`
    },
    dateEqual () {
      return (a, b) => formatDate(a) === formatDate(b)
    }
  }
}

</script>
<style lang="scss" scoped>
.small {
    font-size: small;
}
.title {
    margin-bottom: 20px;

    &>* {
        margin-right: 20px;
    }
}
</style>
