<template>
  <el-form
    ref="form"
    v-loading="loading"
    :model="model"
    :rules="rules"
    label-position="top"
  >
    <el-form-item
      label="Текст комментария"
      prop="text"
    >
      <custom-editor
        v-model="model.text"
        @onBlur="$refs.form.validateField('text')"
      />
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        :disabled="loading"
        @click="save"
      >
        Сохранить
      </el-button>
      <el-button
        :disabled="loading"
        @click="cancel"
      >
        Отмена
      </el-button>
    </el-form-item>
  </el-form>
</template>
<script>

import api from '@/api/tasks'
import FormMixin from '@/mixins/FormMixin'

export default {
  name: 'FormComment',
  mixins: [FormMixin],
  props: {
    taskId: { type: Number, required: true }
  },
  data () {
    return {
      model: {
        id: null,
        text: ''
      },
      rules: {
        text: [{
          required: true,
          message: 'Комментарий обязателен',
          transform (value) {
            const tag = document.createElement('div')
            tag.innerHTML = value
            return tag.innerText.trim()
          }
        }]
      }
    }
  },
  methods: {
    save () {
      this.saveWrapper(api.saveComment, { ...this.model, task_id: this.taskId })
    },
    updateData () {
      if (this.value) {
        this.model.id = this.value.id
        this.model.text = this.value.text
      } else {
        this.model.id = null
        this.$refs.form.resetFields()
      }
    }
  }
}
</script>
