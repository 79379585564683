<template>
  <div
    :id="id"
    class="block-item-wrapper"
    :class="{highlight}"
  >
    <slot />
  </div>
</template>
<script>
export default {
  name: 'BlockItemWrapper',
  props: {
    id: { type: String, default: null },
    highlight: { type: Boolean, default: false }
  }
}
</script>
<style lang="scss" scoped>
.block-item-wrapper {
    padding: 20px;
    margin: 20px 10px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    border: 1px solid #EBEEF5;
    background: #fff;

    &.highlight {
        background: rgba(0, 196, 0, 0.1);
    }
}
</style>
