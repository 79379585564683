<template>
  <el-main>
    <h1>404</h1>
    <p>Страница не найдена</p>
    <p>
      <el-link
        type="primary"
        icon="el-icon-back"
        @click="goBack"
      >
        вернуться
      </el-link>
    </p>
  </el-main>
</template>
<script>
export default {
  name: 'PageNotFound',
  methods: {
    goBack () {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    }
  }
}
</script>
