<template>
  <el-form
    inline
    label-position="top"
    size="small"
  >
    <el-form-item label="Проекты">
      <el-select
        v-model="projectFilter"
        filterable
      >
        <el-option
          v-for="project in projectsOptions"
          :key="project.id"
          :label="project.title"
          :value="project.id"
        />
      </el-select>
    </el-form-item>
    <el-form-item label="Статус">
      <el-select v-model="statusFilter">
        <el-option
          v-for="item in taskStatuses"
          :key="item.status"
          :label="item.title"
          :value="item.status"
        />
      </el-select>
    </el-form-item>
    <slot />
  </el-form>
</template>
<script>
import taskStatuses from '@/data/taskStatuses'

export default {
  name: 'FilterTask',
  props: {
    projectsOptions: { type: Array, required: true },
    tabName: { type: String, required: true }
  },
  computed: {
    statusFilter: {
      set (status) {
        this.$router.push({ path: '/tasks/' + this.tabName, query: { ...this.$router.currentRoute.query, status, page: 1 } })
      },
      get () {
        return this.$route.query.status || ''
      }
    },
    projectFilter: {
      set (project) {
        this.$router.push({ path: '/tasks/' + this.tabName, query: { ...this.$router.currentRoute.query, project, page: 1 } })
      },
      get () {
        return this.$route.query.project ? parseInt(this.$route.query.project.toString()) : ''
      }
    },
    taskStatuses () {
      return [{ title: 'Все', status: '' }, ...Object.keys(taskStatuses).map(status => {
        return {
          title: taskStatuses[status],
          status
        }
      })]
    }
  }
}
</script>
