<template>
  <el-table
    v-loading="loading"
    :data="tableData"
    style="width: 100%"
  >
    <el-table-column
      label="Название"
      width="200"
    >
      <template #default="scope">
        <el-link
          type="primary"
          @click="edit(scope.row.id)"
        >
          {{ scope.row.title }}
        </el-link>
      </template>
    </el-table-column>
    <el-table-column
      prop="managers"
      label="Менеджер"
      width="200"
    />
    <el-table-column
      prop="createdAt"
      label="Дата создания"
      width="200"
    />
    <el-table-column
      prop="updatedAt"
      label="Дата изменения"
      width="200"
    />
    <el-table-column
      prop="workers"
      label="Исполнители"
    />
  </el-table>
</template>
<script>
import { formatDateTime, workersNames } from '@/filters'
import { mapGetters } from 'vuex'

export default {
  name: 'TableProject',
  props: {
    projectItems: { type: Array, required: true },
    showArchive: { type: Boolean, required: true },
    loading: { type: Boolean, required: true }
  },
  computed: {
    ...mapGetters('users', ['userItems']),
    tableData () {
      return this.projectItems.filter(project => this.showArchive || !project.is_archive).map(item => {
        return {
          id: item.id,
          title: item.title + (item.is_archive ? ' (архив)' : ''),
          managers: workersNames(item.managers, this.userItems),
          createdAt: formatDateTime(item.created_at),
          updatedAt: formatDateTime(item.updated_at),
          workers: workersNames(item.workers, this.userItems)
        }
      })
    }
  },
  methods: {
    edit (id) {
      this.$emit('edit', id)
    }
  }
}
</script>
