<template>
  <div class="date-select">
    <el-button
      size="mini"
      icon="el-icon-back"
      circle
      @click="goPrev"
    />
    <span
      class="period-text"
      :style="{'min-width': minWidth + 'px'}"
    >{{ periodText }}</span>
    <el-button
      size="mini"
      :disabled="isNextButtonDisabled"
      icon="el-icon-right"
      circle
      @click="goNext"
    />
  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'DateSelect',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: { type: Date, default () { return new Date() } },
    type: { type: String, validator: value => ['month', 'week'].includes(value), default: 'month' },
    lockMax: { type: Boolean, default: false },
    minWidth: { type: Number, default: 170 }
  },
  computed: {
    isNextButtonDisabled () {
      return this.lockMax && moment(this.value).add(1, this.type).toDate() > (new Date())
    },
    periodText () {
      return moment(this.value).format(this.type === 'month' ? 'MMMM YYYY' : 'w неделя YYYY') + ' года'
    }
  },
  methods: {
    goNext () {
      this.$emit('change', moment(this.value).add(1, this.type).toDate())
    },
    goPrev () {
      this.$emit('change', moment(this.value).subtract(1, this.type).toDate())
    }
  }
}
</script>
<style lang="scss" scoped>
.date-select {
  display: inline-block;
}
.period-text{
  font-weight: bold;
  display: inline-block;
  text-align: center;
  padding: 0 5px;
  box-sizing: border-box;
  vertical-align: middle;
}
</style>
