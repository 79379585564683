<template>
  <div>
    <el-table
      v-loading="loading"
      :data="tableData"
      style="width: 100%"
      class="task-table"
    >
      <el-table-column label="Название">
        <template #default="scope">
          <el-link
            type="primary"
            :href="`/tasks/${tabName}/${scope.row.id}`"
          >
            {{ scope.row.title }} #{{ scope.row.id }}
          </el-link>
        </template>
      </el-table-column>
      <el-table-column
        prop="project"
        label="Проект"
        width="200"
      />
      <el-table-column
        prop="dateCreated"
        label="Дата создания"
        width="150"
      />
      <el-table-column
        prop="dateEnd"
        label="Дата завершения"
        width="150"
      />
      <el-table-column
        prop="minutesEstimated"
        label="Оценка"
        width="100"
      />
      <el-table-column
        prop="minutesReal"
        label="Затрачено"
        width="100"
      />
      <el-table-column
        prop="workers"
        label="Исполниители"
        width="300"
      />
      <el-table-column
        prop="status"
        label="Статус"
        width="150"
      />
      <el-table-column v-if="showStatusControls">
        <template slot-scope="scope">
          <task-status-controls
            v-model="scope.row.task"
            tooltip-placement="left"
            @change="$emit('change')"
          />
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      v-if="totalCount > 25"
      :current-page.sync="page"
      background
      layout="prev, pager, next"
      :page-size="25"
      :total="totalCount"
      :disabled="loading"
    />
  </div>
</template>
<script>
import TaskStatusControls from '@/components/blocks/TaskStatusControls'
export default {
  name: 'TableTask',
  components: { TaskStatusControls },
  props: {
    totalCount: { type: Number, required: true },
    loading: { type: Boolean, default: false },
    tableData: { type: Array, required: true },
    currentPage: { type: Number, required: true },
    showStatusControls: { type: Boolean, default: false },
    tabName: { type: String, required: true }
  },
  computed: {
    page: {
      get () {
        return this.currentPage
      },
      set (value) {
        this.$emit('update:currentPage', value)
      }
    }
  }
}
</script>
<style>
.task-table tr td .cell {
    word-break: break-word;
}
</style>
