import roles from '@/data/roles'
import taskStatuses from '@/data/taskStatuses'
import moment from 'moment'

export const toHours = minutes => Math.floor(minutes / 60) + 'ч' + (minutes % 60 ? ` ${minutes % 60}м` : '')
export const roleTitle = role => roles[role] || 'Гость'
export const taskStatusTitle = status => taskStatuses[status] || 'Неивестен'
export const formatDateTime = date => date ? moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm:ss') : ''
export const formatDate = date => date ? moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY') : ''
export const userNameById = (id, users) => id ? (users.find(user => user.id === id) || { name: '' }).name : ''
export const projectNameById = (id, projects) => id ? (projects.find(project => project.id === id) || { title: '' }).title : ''
export const workersNames = (items, users) => users.filter(user => items.includes(user.id)).map(user => user.name).join(', ')
