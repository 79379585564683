<script>
export default {
  name: 'CustomEditorView',
  functional: true,
  props: {
    value: { type: String, default: '' }
  },
  render (h, { props }) {
    return h('div', {
      domProps: {
        className: 'el-tiptap-editor__content',
        innerHTML: props.value
      }
    })
  }
}
</script>
