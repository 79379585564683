import { mapActions, mapGetters } from 'vuex'
import { formatDate, projectNameById, taskStatusTitle, toHours, workersNames } from '@/filters'
import api from '@/api/tasks'
import FormTask from '@/components/forms/FormTask'
import TableTask from '@/components/tables/TableTask'
import FilterTask from '@/components/forms/FilterTask'

export default {
  components: {
    FormTask,
    TableTask,
    FilterTask
  },
  data () {
    return {
      taskItems: [],
      loading: true,
      showCreatePopup: false,
      totalCount: 0,
      tabName: null
    }
  },
  watch: {
    '$route.query' () {
      this.loadData()
    }
  },
  computed: {
    ...mapGetters('users', ['userItems']),
    ...mapGetters('projects', ['projectItems']),

    tableData () {
      return this.taskItems.map(task => {
        return {
          task: task,
          id: task.id,
          title: task.title,
          project: projectNameById(task.project_id, this.projectItems),
          dateCreated: formatDate(task.created_at),
          dateEnd: formatDate(task.date_end),
          minutesEstimated: toHours(task.minutes_estimated),
          minutesReal: toHours(task.minutes_real),
          workers: workersNames(task.workers, this.userItems),
          status: taskStatusTitle(task.status)
        }
      })
    },
    page: {
      set (page) {
        if (page !== this.page) {
          this.$router.push({ url: `tasks/${this.tabName}`, query: { ...this.$router.currentRoute.query, page } })
        }
      },
      get () {
        return parseInt(this.$route.query.page || 1)
      }
    }
  },
  mounted () {
    this.loadUsers().then(() => {
      this.loadProjects().then(() => {
        this.loadData()
      })
    })
  },
  methods: {
    ...mapActions('users', ['loadUsers']),
    ...mapActions('projects', ['loadProjects']),
    closeCreatePopup () {
      this.showCreatePopup = false
    },
    closePopupAndUpdate () {
      this.closeCreatePopup()
      this.loadData()
    },
    loadData () {
      this.loading = true
      api.loadTaskIndex({ ...this.$route.query, type: this.tabName }).then(data => {
        this.taskItems = data.items
        this.totalCount = data.count
      }).catch(() => {
        this.$notify({
          title: 'Ошибка',
          message: 'Ошибка при подключении к серверу',
          type: 'error'
        })
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
