export default {
  props: ['checkRefresh', 'value'],
  data () {
    return {
      model: {},
      rules: {},
      errors: {},
      loading: false
    }
  },
  methods: {
    updateData () {
      if (this.value) {
        Object.assign(this.model, this.value)
      } else {
        this.$refs.form.resetFields()
      }
    },
    saveWrapper (saveMethod, data) {
      Object.keys(this.errors).forEach(key => {
        this.errors[key] = ''
      })
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          saveMethod(data).then(() => {
            this.$emit('save')
          }).catch(message => {
            if (!message.errors) {
              this.$notify({
                title: 'Ошибка',
                message: 'Невозможно отправить данные',
                type: 'error'
              })
            } else {
              Object.keys(message.errors).forEach(key => {
                if (this.errors[key] === undefined) {
                  console.error('Необработанная ощибка валидации', key)
                } else {
                  this.errors[key] = message.errors[key].join('<br>')
                }
              })
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    cancel () {
      this.$emit('cancel')
    }
  },
  watch: {
    value () {
      this.updateData()
    },
    checkRefresh () {
      this.updateData()
    }
  },
  mounted () {
    this.updateData()
  }
}
