import api from '@/api/users'

const state = {
  items: []
}

const getters = {
  userItems: state => state.items
}

const actions = {
  loadUsers ({ commit }) {
    return api.load().then(data => {
      commit('setUsers', data)
      return data
    })
  },
  saveUser ({ commit }, user) {
    return api.save(user).then(data => {
      commit(!user.id ? 'addUser' : 'updateUser', data)
      return data
    })
  }
}

const mutations = {
  setUsers (state, data) {
    state.items = data
  },
  updateUser (state, data) {
    state.items.splice(state.items.findIndex(item => item.id === data.id), 1)
    state.items.unshift({ ...data })
  },
  addUser (state, data) {
    state.items.unshift({ ...data })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
