import api from './api'

export default {
  load: id => api.get(`tasks/${id}`, {}, true),
  save: data => api.post('tasks' + (data.id ? `/${data.id}` : ''), data),
  loadTaskIndex: params => api.get('tasks', { params }),
  setStatus: data => api.post('tasks/set-status', data),
  saveTime: data => api.post('tasks/save-worktime', data),
  saveComment: data => api.post('tasks/save-comment', data),
  todayTimeAvailable: day => api.get('tasks/today-time-available', { params: { day } })
}
