<template>
  <el-main>
    <h2>Управление пользователями</h2>

    <el-form
      inline
      label-position="top"
      size="small"
    >
      <el-form-item>
        <el-checkbox v-model="showArchive">
          Показывать архивные
        </el-checkbox>
      </el-form-item>
    </el-form>

    <el-button
      type="primary"
      @click="showEditPopup = true"
    >
      Добавить пользователя
    </el-button>

    <table-user
      :users="filteredUsers"
      :loading="loading"
      @edit="editUser"
    />

    <el-dialog
      :visible="showEditPopup"
      :title="dialogTitle"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <form-user
        :value="selectedUser"
        :check-refresh="showEditPopup"
        @cancel="closeEditPopup"
        @save="closeEditPopup"
      />
    </el-dialog>
  </el-main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import FormUser from '../forms/FormUser'
import TableUser from '@/components/tables/TableUser'

export default {
  name: 'IndexUser',
  components: {
    TableUser,
    FormUser
  },
  data () {
    return {
      showEditPopup: false,
      selectedUser: null,
      loading: true
    }
  },
  computed: {
    ...mapGetters('users', ['userItems']),
    dialogTitle () {
      return this.selectedUser !== null ? 'Редактирование пользователя' : 'Добавление пользователя'
    },
    showArchive: {
      set (archive) {
        this.$router.push({ path: '/users', query: { ...this.$router.currentRoute.query, archive: archive ? 1 : '' } })
      },
      get () {
        return !!(this.$route.query.archive || false)
      }
    },
    filteredUsers () {
      return this.showArchive ? this.userItems : this.userItems.filter(user => user.status)
    }
  },
  mounted () {
    this.loadUsers().finally(() => {
      this.loading = false
    })
  },
  methods: {
    ...mapActions('users', ['loadUsers']),
    editUser (userId) {
      this.selectedUser = this.userItems.find(user => user.id === userId)
      this.showEditPopup = true
    },
    closeEditPopup () {
      this.selectedUser = null
      this.showEditPopup = false
    }
  }
}
</script>
