import api from '@/api/auth'

const storage = {
  load () {
    const data = localStorage.getItem('auth')
    return data ? JSON.parse(data) : null
  },
  save (data) {
    localStorage.setItem('auth', JSON.stringify(data))
  },
  clear () {
    localStorage.removeItem('auth')
  }
}
const state = storage.load() || {
  user: null,
  token: null
}

if (state.token) {
  api.setToken(state.token)
}

const mutations = {
  clearAuthData (state) {
    state.token = state.user = null
    storage.clear()
  },

  setAuthData (state, { user, token }) {
    state.user = user
    state.token = token
    storage.save(state)
    api.setToken(token)
  },

  setUserData (state, data) {
    state.user = data
    storage.save(state)
  }
}

const actions = {
  saveProfile ({ commit }, data) {
    return api.saveProfile(data).then(data => {
      commit('setUserData', data)
      return data
    })
  },
  login ({ commit }, { email, password }) {
    return api.login(email, password).then(data => {
      commit('setAuthData', data)
      return data
    })
  },
  logout ({ commit }) {
    return api.logout().then(() => {
      commit('clearAuthData')
    }).catch(response => {
      console.error(response)
    })
  }
}

const getters = {
  token: state => state.token,
  userData: state => state.user || {},
  role: state => state.user ? state.user.role : 'guest',
  isUnauthorized: state => !state.token
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
