<template>
  <el-main>
    <h2>Задачи в моих проектах</h2>

    <filter-task
      :tab-name="tabName"
      :projects-options="projectsOptions"
    >
      <el-form-item label="Исполнители">
        <el-select
          v-model="workersFilter"
          filterable
          multiple
          style="width: 400px;"
        >
          <el-option
            v-for="worker in workerItems"
            :key="worker.id"
            :label="worker.name"
            :value="worker.id"
          />
        </el-select>
      </el-form-item>
    </filter-task>

    <el-button
      type="primary"
      @click="showCreatePopup = true"
    >
      Создать задачу
    </el-button>

    <table-task
      :current-page.sync="page"
      :total-count="totalCount"
      :loading="loading"
      :table-data="tableData"
      :tab-name="tabName"
      show-status-controls
      @change="loadData"
    />

    <el-dialog
      :visible="showCreatePopup"
      title="Создание задачи"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <form-task
        :check-refresh="showCreatePopup"
        :user-items="workerItems"
        :project-items="availableProjects"
        @cancel="closeCreatePopup"
        @save="closePopupAndUpdate"
      />
    </el-dialog>
  </el-main>
</template>
<script>

import TaskIndexMixin from '@/mixins/TaskIndexMixin'
import { mapGetters } from 'vuex'

export default {
  name: 'IndexTaskManager',
  mixins: [TaskIndexMixin],
  data () {
    return {
      tabName: 'manager'
    }
  },
  computed: {
    ...mapGetters('auth', ['userData']),
    workersFilter: {
      set (workers) {
        this.$router.push({ path: '/tasks/' + this.tabName, query: { ...this.$router.currentRoute.query, workers: workers.join(','), page: 1 } })
      },
      get () {
        return this.$route.query.workers ? this.$route.query.workers.split(',').map(v => parseInt(v)) : []
      }
    },
    projectsOptions () {
      return [{
        id: '',
        title: 'Все'
      }, ...this.availableProjects]
    },
    availableProjects () {
      return this.projectItems.filter(project => project.managers.includes(this.userData.id) && !project.is_archive)
    },
    workerItems () {
      return this.userItems.filter(user => this.availableProjects.find(project => project.workers.includes(user.id)) !== undefined)
    }
  }
}
</script>
