<template>
  <el-main>
    <el-form
      ref="form"
      :model="model"
      :rules="rules"
      label-position="top"
    >
      <el-form-item
        v-loading="loading"
        prop="worker"
        label="Сотрудник"
      >
        <el-select
          v-model="model.worker"
          filterable
        >
          <el-option
            v-for="user in users"
            :key="user.id"
            :label="user.name"
            :value="user.id"
          />
        </el-select>
      </el-form-item>

      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item
            prop="date"
            label="Месяц"
          >
            <el-date-picker
              v-model="model.date"
              type="month"
              value-format="yyyy-MM-dd"
              format="MMMM yyyy"
              :clearable="false"
              :editable="false"
              :picker-options="{ firstDayOfWeek: 1 }"
            />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item
            prop="number"
            label="Номер акта"
          >
            <el-input v-model="model.number" />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item
            prop="contract"
            label="Номер договора"
          >
            <el-input v-model="model.contract" />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item
            prop="price"
            label="Цена часа"
          >
            <el-input v-model.number="model.price" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-link
      v-if="downloadUrl"
      :href="downloadUrl"
      target="_blank"
    >
      Скачать акт
    </el-link>
  </el-main>
</template>

<script>
import usersApi from '@/api/users'
import api from '@/api/api'
import { mapGetters } from 'vuex'

export default {
  name: 'PageAct',
  data () {
    return {
      loading: true,
      model: {
        number: '',
        date: '',
        contract: '',
        worker: undefined,
        price: ''
      },
      rules: {
        number: [
          { required: true, trigger: 'blur' }
        ],
        contract: [
          { required: true, trigger: 'blur' }
        ],
        date: [
          { required: true, trigger: 'blur' }
        ],
        worker: [
          { required: true, trigger: 'blur' }
        ],
        price: [
          { required: true, trigger: 'blur' }
        ]
      },
      users: []
    }
  },
  computed: {
    ...mapGetters('auth', ['token']),
    downloadUrl () {
      for (const key in this.model) {
        if (!this.model[key]) {
          return ''
        }
      }
      return api.domain + '/download/export/act?' +
        (new URLSearchParams({ ...this.model, 'access-token': this.token })).toString()
    }
  },
  mounted () {
    usersApi.loadSelfEmployed().then(result => {
      this.users = result
      this.loading = false
    })
  }
}
</script>
