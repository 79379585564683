import Vue from 'vue'
import App from '@/components/App.vue'
import store from './store'
import router from './router'
import moment from 'moment'

import ElementUI from 'element-ui'
import { ElementTiptapPlugin } from 'element-tiptap'

import 'element-ui/lib/theme-chalk/index.css'
import 'element-tiptap/lib/index.css'

import lang from 'element-ui/lib/locale/lang/ru-RU'
import locale from 'element-ui/lib/locale'

import CustomEditor from '@/components/global/CustomEditor'
import CustomEditorView from '@/components/global/CustomEditorView'
import InputHours from '@/components/global/InputHours'
import DateSelect from '@/components/global/DateSelect'

Vue.use(ElementUI)
Vue.use(ElementTiptapPlugin, { lang: 'ru' })

locale.use(lang)

Vue.component(CustomEditor.name, CustomEditor)
Vue.component(CustomEditorView.name, CustomEditorView)
Vue.component(InputHours.name, InputHours)
Vue.component(DateSelect.name, DateSelect)

moment.locale('ru')

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
