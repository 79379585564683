<template>
  <el-form
    ref="form"
    v-loading="loading"
    :model="model"
    :rules="rules"
    label-width="200px"
  >
    <el-form-item
      label="Название"
      prop="title"
      :error="errors.title"
    >
      <el-input v-model="model.title" />
    </el-form-item>
    <el-form-item
      label="Ссылка на задачу"
      prop="link"
    >
      <el-input v-model="model.link" />
    </el-form-item>
    <el-form-item
      v-if="model.id === null"
      label="Проект"
      prop="project_id"
      :error="errors.project_id"
    >
      <el-select
        v-model="model.project_id"
        filterable
      >
        <el-option
          v-for="project in projectItems"
          :key="project.id"
          :label="project.title"
          :value="project.id"
        />
      </el-select>
    </el-form-item>
    <el-form-item
      label="Исполнители"
      prop="workers"
    >
      <el-select
        v-model="model.workers"
        filterable
        multiple
        style="width: 100%;"
        :disabled="!model.project_id"
      >
        <el-option
          v-for="worker in projectWorkers"
          :key="worker.id"
          :label="worker.name"
          :value="worker.id"
        />
      </el-select>
    </el-form-item>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item
          label="Дата начала"
          prop="date_start"
          :error="errors.date_start"
        >
          <el-date-picker
            v-model="model.date_start"
            type="date"
            value-format="yyyy-MM-dd"
            format="dd.MM.yyyy"
            :picker-options="{ firstDayOfWeek: 1 }"
          />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item
          label="Дата завершения"
          prop="date_end"
        >
          <el-tooltip
            effect="dark"
            content="При создании задачи считается автоматически из даты начала и оценки, проверяйте правильность"
            placement="top"
            :disabled="!canUpdateDateEnd"
          >
            <el-date-picker
              v-model="model.date_end"
              type="date"
              value-format="yyyy-MM-dd"
              :picker-options="{ firstDayOfWeek: 1 }"
              format="dd.MM.yyyy"
            />
          </el-tooltip>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item
          label="Оценка"
          prop="minutes_estimated"
          :error="errors.minutes_estimated"
        >
          <input-hours
            v-model="model.minutes_estimated"
            @blur="calculateDateEnd"
          />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item
          v-if="showTimeClient"
          label="Оценка для клиента"
          prop="minutes_client"
        >
          <input-hours v-model="model.minutes_client" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item
      label="Описание задачи"
      prop="description"
    >
      <custom-editor v-model="model.description" />
    </el-form-item>

    <el-form-item>
      <el-button
        type="primary"
        :disabled="loading"
        @click="save"
      >
        Сохранить
      </el-button>
      <el-button
        :disabled="loading"
        @click="cancel"
      >
        Отмена
      </el-button>
    </el-form-item>
  </el-form>
</template>
<script>

import { mapGetters } from 'vuex'
import api from '@/api/tasks'
import FormMixin from '@/mixins/FormMixin'
import moment from 'moment'

export default {
  name: 'FormTask',
  mixins: [FormMixin],
  props: {
    value: { type: Object, default: null },
    userItems: { type: Array, required: true },
    projectItems: { type: Array, required: true },
    defaultWorker: { type: Number, default: null }
  },
  data () {
    return {
      model: {
        id: null,
        project_id: null,
        title: '',
        date_start: moment().format('YYYY-MM-DD'),
        date_end: '',
        minutes_estimated: 0,
        minutes_client: 0,
        status: 'work',
        workers: [],
        description: '',
        link: null
      },
      errors: {
        title: '',
        project_id: '',
        date_start: '',
        minutes_estimated: ''
      },
      rules: {
        title: [
          { required: true, message: 'Обязательно для заполнения', trigger: 'blur' }
        ],
        project_id: [
          { required: true, message: 'Обязательно для заполнения', trigger: 'blur' }
        ],
        date_start: [
          { required: true, message: 'Обязательно для заполнения', trigger: 'blur' }
        ],
        workers: [
          { required: true, type: 'array', length: { min: 1 }, message: 'Укажите минимум одного исполнителя', trigger: 'change' }
        ],
        link: [
          { type: 'url', trigger: 'blur', message: 'Неправильная ссылка' }
        ]
      },
      canUpdateDateEnd: true
    }
  },
  computed: {
    ...mapGetters('auth', ['userData', 'role']),
    selectedProject () {
      return this.projectItems.find(project => project.id === this.model.project_id)
    },
    showTimeClient () {
      return this.role === 'admin' || (this.selectedProject && this.selectedProject.managers.includes(this.userData.id))
    },
    projectWorkers () {
      return this.userItems.filter(this.model.project_id
        ? user => user.status && this.selectedProject.workers.includes(user.id)
        : user => user.id === this.defaultWorker
      )
    }
  },
  watch: {
    'model.date_start' () {
      this.calculateDateEnd()
    },
    'model.minutes_estimated' () {
      this.calculateDateEnd()
    },
    'model.project_id' () {
      if (this.selectedProject) {
        const projectWorkers = this.projectWorkers.map(worker => worker.id)
        this.model.workers = this.model.workers.filter(worker => projectWorkers.includes(worker))
        if (!this.model.workers.length) {
          if (projectWorkers.length === 1) {
            this.model.workers.push(projectWorkers[0])
          }
          if (this.defaultWorker) {
            this.model.workers = [this.defaultWorker]
          }
        }
      } else {
        this.model.workers = []
      }
    }
  },
  methods: {
    save () {
      this.saveWrapper(api.save, this.model)
    },
    updateData () {
      if (this.value) {
        Object.assign(this.model, this.value)
      } else {
        this.$refs.form.resetFields()
      }
      this.canUpdateDateEnd = this.model.date_end === ''
    },
    calculateDateEnd () {
      if (this.canUpdateDateEnd && this.model.date_start && this.model.minutes_estimated) {
        const date = moment(this.model.date_start, 'YYYY-MM-DD')
        for (let days = Math.ceil(this.model.minutes_estimated / (8 * 60)); days > 1; days--) {
          switch (date.weekday()) {
            case 5:
              date.add(3, 'days')
              break
            case 6:
              date.add(2, 'days')
              break
            default:
              date.add(1, 'day')
          }
        }
        this.model.date_end = date.format('YYYY-MM-DD')
      }
    }
  }
}
</script>
