<template>
  <el-form
    ref="form"
    v-loading="loading"
    :model="model"
    :rules="rules"
    label-width="200px"
  >
    <el-form-item
      label="Название"
      prop="title"
      :error="errors.title"
    >
      <el-input
        v-model="model.title"
        :disabled="model.is_archive"
      />
    </el-form-item>
    <el-form-item
      label="Ссылка на проект"
      prop="link"
    >
      <el-input
        v-model="model.link"
        :disabled="model.is_archive"
      />
    </el-form-item>
    <el-form-item
      label="Менеджер"
      prop="managers"
    >
      <el-select
        v-model="model.managers"
        :disabled="model.is_archive"
        filterable
        multiple
      >
        <el-option
          v-for="worker in workers"
          :key="worker.id"
          :label="worker.name"
          :value="worker.id"
        />
      </el-select>
    </el-form-item>
    <el-form-item
      label="Исполнители"
      prop="workers"
    >
      <el-select
        v-model="model.workers"
        :disabled="model.is_archive"
        filterable
        multiple
      >
        <el-option
          v-for="worker in workers"
          :key="worker.id"
          :label="worker.name"
          :value="worker.id"
        />
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        :disabled="loading"
        @click="save"
      >
        Сохранить
      </el-button>
      <el-button
        :disabled="loading"
        @click="cancel"
      >
        Отмена
      </el-button>
      <div style="float: right">
        <el-popconfirm
          v-if="canToArchive"
          confirm-button-text="Да"
          cancel-button-text="Нет"
          title="Отправить этот проект в архив?"
          @confirm="sendToArchive"
        >
          <el-button
            slot="reference"
            type="danger"
            :disabled="loading"
          >
            Отправить в архив
          </el-button>
        </el-popconfirm>
        <el-popconfirm
          v-if="canFromArchive"
          confirm-button-text="Да"
          cancel-button-text="Нет"
          title="Вернуть этот проект из архива?"
          @confirm="returnFromArchive"
        >
          <el-button
            slot="reference"
            type="warning"
            :disabled="loading"
          >
            Вернуть из архива
          </el-button>
        </el-popconfirm>
      </div>
    </el-form-item>
  </el-form>
</template>
<script>

import roles from '@/data/roles'
import { mapActions } from 'vuex'
import FormMixin from '@/mixins/FormMixin'

export default {
  name: 'FormProject',
  mixins: [FormMixin],
  props: {
    value: { type: Object, default: null },
    userItems: { type: Array, required: true }
  },
  data () {
    return {
      model: {
        id: null,
        title: '',
        is_archive: false,
        link: null,
        managers: [],
        workers: []
      },
      errors: {
        title: ''
      },
      rules: {
        title: [
          { required: true, message: 'Обязательно для заполнения', trigger: 'blur' }
        ],
        link: [
          { type: 'url', trigger: 'blur', message: 'Неправильная ссылка' }
        ]
      }
    }
  },
  computed: {
    rolesOptions () {
      return Object.keys(roles).map(key => {
        return {
          value: key,
          label: roles[key]
        }
      })
    },
    workers () {
      return this.userItems.filter(user => user.status)
    },
    canToArchive () {
      return this.model.id && !this.model.is_archive
    },
    canFromArchive () {
      return this.model.is_archive
    }
  },
  methods: {
    ...mapActions('projects', ['saveProject', 'setArchive']),
    save () {
      this.saveWrapper(this.saveProject, this.model)
    },
    updateData () {
      if (this.value) {
        Object.assign(this.model, this.value)
      } else {
        this.$refs.form.resetFields()
        this.model.id = null
      }
    },
    sendArchiveData (isArchive) {
      this.loading = true
      this.setArchive({ id: this.model.id, isArchive }).then(() => {
        this.$emit('save')
      }).catch(() => {
        this.$notify({
          title: 'Ошибка',
          message: 'Невозможно отправить данные',
          type: 'error'
        })
      }).finally(() => {
        this.loading = false
        this.model.is_archive = isArchive
      })
    },
    sendToArchive () {
      this.sendArchiveData(true)
    },
    returnFromArchive () {
      this.sendArchiveData(false)
    }
  }
}
</script>
