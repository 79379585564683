import api from './api'

export default {
  login: (email, password) => api.post('auth/login', { email, password }),
  logout: () => {
    delete api.instance.defaults.headers.common['X-Api-Key']
    return api.post('auth/logout')
  },
  setToken: token => { api.instance.defaults.headers.common['X-Api-Key'] = token },
  saveProfile: data => api.post('auth/save-profile', data)
}
