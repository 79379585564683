<template>
  <el-table
    v-loading="loading"
    :data="tableData"
    style="width: 100%"
  >
    <el-table-column type="expand">
      <template slot-scope="scope">
        <p
          v-for="(item, index) in scope.row.items"
          :key="index"
        >
          <el-link
            :href="'/tasks/all/' + item.task.id"
            target="_blank"
            type="primary"
          >
            {{ item.task.title }}
          </el-link>
          ({{ item.hours }})
        </p>
      </template>
    </el-table-column>
    <el-table-column
      label="ФИО"
      width="200"
    >
      <template #default="scope">
        <el-link
          type="primary"
          @click="$emit('select', { id: scope.row.worker.id, entity: 'worker' } )"
        >
          {{ scope.row.worker.title }}
        </el-link>
      </template>
    </el-table-column>
    <el-table-column
      label="Проект"
      width="200"
    >
      <template #default="scope">
        <el-link
          type="primary"
          @click="$emit('select', { id: scope.row.project.id, entity: 'project' } )"
        >
          {{ scope.row.project.title }}
        </el-link>
      </template>
    </el-table-column>
    <el-table-column
      prop="hours_client"
      label="Время для клиента"
      width="100"
    />
    <el-table-column
      prop="hours"
      label="Затраченное время"
      width="100"
    />
    <el-table-column
      prop="hours_estimated"
      label="Оценка"
      width="100"
    />
  </el-table>
</template>
<script>
import { toHours } from '@/filters'

export default {
  name: 'TableReport',
  props: {
    items: { type: Array, required: true },
    loading: { type: Boolean, default: false },
    users: { type: Array, required: true },
    projects: { type: Array, required: true },
    projectId: { type: Number, default: null },
    workerId: { type: Number, default: null }
  },
  computed: {
    tableData () {
      const rows = []

      this.items
        .filter(item => (item.project_id === this.projectId || !this.projectId) && (item.worker_id === this.workerId || !this.workerId))
        .forEach(item => {
          let row = rows.find(r => r.project.id === item.project_id && r.worker.id === item.worker_id)

          if (row === undefined) {
            row = {
              worker: {
                id: item.worker_id,
                title: this.users.find(user => user.id === item.worker_id).name
              },
              project: {
                id: item.project_id,
                title: this.projects.find(project => project.id === item.project_id).title
              },
              minutes: 0,
              hours_estimated: toHours(item.minutes_estimated),
              hours_client: toHours(item.minutes_client),
              items: []
            }
            rows.push(row)
          }

          row.minutes += item.minutes
          row.items.push({
            task: {
              id: item.task_id,
              title: item.task_title
            },
            hours: toHours(item.minutes)
          })
        })

      rows.forEach(item => {
        item.hours = toHours(item.minutes)
      })

      return rows
    }
  }
}
</script>
