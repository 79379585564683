<template>
  <span class="status-controls">
    <el-button
      v-if="canTest"
      type="success"
      :size="size"
      :disabled="loading"
      @click="setStatus('test')"
    >
      На проверку
    </el-button>
    <task-status-controls-done
      v-if="canComplete"
      :task="task"
      :tooltip-placement="tooltipPlacement"
      :disabled="loading"
      :size="size"
      @confirm="sendData"
    />
    <el-button
      v-if="canReturnWork"
      type="danger"
      :size="size"
      :disabled="loading"
      @click="setStatus('work')"
    >
      На доработку
    </el-button>
    <el-button
      v-if="canArchive"
      type="danger"
      :size="size"
      :disabled="loading"
      @click="setStatus('archive')"
    >
      В архив
    </el-button>
  </span>
</template>
<script>
import api from '@/api/tasks'
import { mapGetters } from 'vuex'
import TaskStatusControlsDone from '@/components/blocks/TaskStatusControlsDone'

export default {
  name: 'TaskStatusControls',
  components: { TaskStatusControlsDone },
  model: {
    prop: 'task',
    event: 'change'
  },
  props: {
    loading: { type: Boolean, default: false },
    task: { type: Object, required: true },
    size: { type: String, default: 'small' },
    tooltipPlacement: { type: String, default: 'bottom' }
  },
  computed: {
    ...mapGetters('projects', ['projectItems']),
    ...mapGetters('auth', ['userData', 'role']),
    ...mapGetters('users', ['userItems']),
    project () {
      return this.projectItems.find(project => project.id === this.task.project_id)
    },
    userIsManager () {
      return this.role === 'admin' || this.project.managers.includes(this.userData.id)
    },
    canArchive () {
      return this.task.status === 'done' && this.userIsManager
    },
    canTest () {
      return this.task.status === 'work'
    },
    canReturnWork () {
      return this.task.status === 'test' && this.userIsManager
    },
    canComplete () {
      return this.task.status === 'test' && this.userIsManager
    }
  },
  methods: {
    sendData (data) {
      this.$emit('update:loading', true)
      api.setStatus(data).then(result => {
        this.$emit('change', result)
      }).catch(() => {
        this.$notify({
          title: 'Ошибка',
          message: 'Невозможно отправить данные',
          type: 'error'
        })
      }).finally(() => {
        this.$emit('update:loading', false)
      })
    },
    setStatus (status) {
      this.sendData({
        id: this.task.id,
        status
      })
    }
  }
}
</script>
<style lang="scss">
.status-controls {
  button {
    margin-left: 10px;
  }
}
</style>
