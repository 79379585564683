<template>
  <div id="app">
    <page-login v-if="isUnauthorized" />
    <template v-else>
      <el-header>
        <top-navbar />
      </el-header>

      <router-view />
    </template>
  </div>
</template>

<script>
import PageLogin from '@/components/pages/PageLogin'
import TopNavbar from '@/components/blocks/TopNavbar'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    PageLogin,
    TopNavbar
  },
  computed: {
    ...mapGetters('auth', ['isUnauthorized'])
  }
}
</script>
<style>
html, body {
    padding: 0;
    margin: 0;
}
header {
    background: #545c64;
}
</style>
