<template>
  <el-main>
    <h2>Мои задачи</h2>

    <filter-task
      :tab-name="tabName"
      :projects-options="projectsOptions"
    />

    <el-button
      type="primary"
      @click="showCreatePopup = true"
    >
      Создать задачу
    </el-button>

    <table-task
      :current-page.sync="page"
      :total-count="totalCount"
      :loading="loading"
      :table-data="tableData"
      :tab-name="tabName"
    />

    <el-dialog
      :visible="showCreatePopup"
      title="Создание задачи"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <form-task
        :check-refresh="showCreatePopup"
        :user-items="userItems"
        :project-items="availableProjects"
        :default-worker="userData.id"
        @cancel="closeCreatePopup"
        @save="closePopupAndUpdate"
      />
    </el-dialog>
  </el-main>
</template>
<script>

import { mapGetters } from 'vuex'
import TaskIndexMixin from '@/mixins/TaskIndexMixin'

export default {
  name: 'IndexTaskMy',
  mixins: [TaskIndexMixin],
  data () {
    return {
      tabName: 'my'
    }
  },
  computed: {
    ...mapGetters('auth', ['userData']),
    projectsOptions () {
      return [{
        id: '',
        title: 'Все'
      }, ...this.availableProjects]
    },
    availableProjects () {
      return this.projectItems.filter(project => project.workers.includes(this.userData.id) && !project.is_archive)
    }
  }
}
</script>
