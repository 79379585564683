<template>
  <el-form
    ref="form"
    v-loading="loading"
    :model="model"
    :rules="rules"
    label-width="200px"
  >
    <h2>Редактирование профиля</h2>

    <el-form-item
      label="EMail"
      prop="email"
      :error="errors.email"
    >
      <el-input
        v-model="model.email"
        type="email"
      />
    </el-form-item>
    <el-form-item
      label="ФИО"
      prop="name"
      :error="errors.name"
    >
      <el-input v-model="model.name" />
    </el-form-item>
    <el-form-item
      label="Телефон"
      prop="phone"
      :error="errors.phone"
    >
      <el-input v-model="model.phone" />
    </el-form-item>
    <el-form-item
      label="Текущий пароль"
      prop="password"
      :error="errors.password"
    >
      <el-input
        v-model="model.password"
        show-password
      />
    </el-form-item>
    <el-form-item
      label="Новый пароль"
      prop="newPassword"
      :error="errors.newPassword"
    >
      <el-input
        v-model="model.newPassword"
        show-password
      />
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        :disabled="loading"
        @click="save"
      >
        Сохранить
      </el-button>
    </el-form-item>
  </el-form>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'FormProfile',
  data () {
    return {
      model: {
        email: '',
        name: '',
        phone: '',
        password: '',
        newPassword: ''
      },
      errors: {
        email: '',
        name: '',
        phone: '',
        password: '',
        newPassword: ''
      },
      rules: {
        name: [
          { required: true, message: 'Обязательно для заполнения', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { type: 'email', message: 'Некорректный email', trigger: 'blur' }
        ],
        password: [
          { required: true, message: 'Для внесения изменения укажите текущий пароль', trigger: 'blur' }
        ]
      },
      loading: false
    }
  },
  computed: {
    ...mapGetters('auth', ['userData'])
  },
  mounted () {
    this.model.name = this.userData.name
    this.model.phone = this.userData.phone
    this.model.email = this.userData.email
  },
  methods: {
    ...mapActions('auth', ['saveProfile']),
    save () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.saveProfile(this.model).then(model => {
            this.$emit('save', model)
            this.$notify({
              title: 'Успешно',
              message: 'Данные сохранены',
              type: 'success'
            })
            this.model.newPassword = this.model.password = ''
          }).catch(errors => {
            if (!errors) {
              this.$notify({
                title: 'Ошибка',
                message: 'Невозможно отправить данные',
                type: 'error'
              })
            } else {
              Object.keys(errors).forEach(key => {
                if (this.errors[key] === undefined) {
                  console.error('Необработанная ощибка валидации', key)
                } else {
                  this.errors[key] = errors[key].join('<br>')
                }
              })
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>
