<template>
  <el-table
    v-loading="loading"
    :data="tableData"
    style="width: 100%"
  >
    <el-table-column
      label="ФИО"
    >
      <template #default="scope">
        <el-link
          type="primary"
          @click="edit(scope.row.id)"
        >
          {{ scope.row.name }}
        </el-link>
      </template>
    </el-table-column>
    <el-table-column
      prop="email"
      label="Email"
      width="250"
    />
    <el-table-column
      prop="phone"
      label="Телефон"
      width="200"
    />
    <el-table-column
      prop="role"
      label="Роль"
      width="200"
    />
    <el-table-column
      prop="createdAt"
      label="Дата регистрации"
      width="200"
    />
    <el-table-column
      prop="updatedAt"
      label="Дата изменения"
      width="200"
    />
    <el-table-column
      prop="loginAt"
      label="Последний вход"
      width="200"
    />
  </el-table>
</template>
<script>
import { formatDateTime, roleTitle } from '@/filters'

export default {
  name: 'TableUser',
  props: {
    loading: { type: Boolean, required: true },
    users: { type: Array, required: true }
  },
  computed: {
    tableData () {
      return this.users.map(user => {
        return {
          id: user.id,
          email: user.email,
          name: user.name,
          phone: user.phone,
          role: roleTitle(user.role),
          createdAt: formatDateTime(user.created_at),
          updatedAt: formatDateTime(user.updated_at),
          loginAt: formatDateTime(user.login_at)
        }
      })
    }
  },
  methods: {
    edit (id) {
      this.$emit('edit', id)
    }
  }
}
</script>
