import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('users', ['userItems']),
    ...mapGetters('projects', ['projectItems']),
    ...mapGetters('auth', ['userData']),
    availableProjects () {
      return this.userData.role === 'admin' ? this.projectItems : this.projectItems.filter(project => project.managers.includes(this.userData.id))
    },
    availableUsers () {
      return this.userData.role === 'admin' ? this.userItems : this.userItems.filter(user => this.availableProjects.find(project => project.workers.includes(user.id)) !== undefined)
    },
    currentDate: {
      set (date) {
        this.go({ date: moment(date).format('YYYY-MM-DD') })
      },
      get () {
        return this.$route.query.date ? moment(this.$route.query.date, 'YYYY-MM-DD').toDate() : new Date()
      }
    }
  },
  methods: {
    go (query) {
      if (Object.keys(query).find(index => this.$route.query[index] !== query[index].toString())) {
        this.$router.push({ path: '/report', query: { ...this.$route.query, ...query }, hash: this.$route.hash })
      }
    }
  }
}
