<template>
  <el-tiptap
    v-model="content"
    :extensions="extensions"
    :char-counter-count="false"
    @onBlur="$emit('onBlur')"
    @onInit="onEditorInit"
  />
</template>
<script>
import {
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Underline,
  Italic,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
  CodeBlock,
  Link,
  TodoList,
  TodoItem,
  Fullscreen,
  Blockquote
} from 'element-tiptap'

export default {
  name: 'CustomEditor',
  props: {
    value: { type: String, default: '' }
  },
  data () {
    return {
      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 5 }),
        new Bold({ bubble: true }),
        new Underline({ bubble: true }),
        new Italic({ bubble: true }),
        new Strike({ bubble: true }),
        new Blockquote(),
        new CodeBlock(),
        new Link({ bubble: true }),
        new ListItem(),
        new BulletList(),
        new OrderedList(),
        new TodoList(),
        new TodoItem(),
        new Fullscreen()
      ]
    }
  },
  computed: {
    content: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    onEditorInit (editor) {
      this.$nextTick(() => {
        const wrapper = this.$el.querySelector('.el-tiptap-editor__content')
        wrapper.addEventListener('click', e => {
          if (e.target === wrapper) {
            editor.editor.focus()
          }
        })
      })
    }
  }
}
</script>
