<template>
  <el-popover
    ref="popover"
    :value="visible"
    trigger="manual"
    :placement="tooltipPlacement"
  >
    <el-form
      ref="doneForm"
      :model="model"
      :rules="rules"
      size="mini"
      label-width="150px"
    >
      <el-form-item
        label="Дата завершения"
        prop="date_end"
      >
        <el-date-picker
          ref="datePicker"
          v-model="model.date_end"
          type="date"
          value-format="yyyy-MM-dd"
          format="dd.MM.yyyy"
          :picker-options="{ firstDayOfWeek: 1 }"
        />
      </el-form-item>
      <el-form-item
        label="Время для клиента"
        prop="minutes"
      >
        <input-hours
          v-model="model.minutes"
          hide-tooltip
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="success"
          @click="doneConfirm"
        >
          Подтвердить
        </el-button>
        <el-button @click="cancelForm">
          Отмена
        </el-button>
      </el-form-item>
    </el-form>
    <el-button
      slot="reference"
      type="success"
      :size="size"
      :disabled="disabled"
      @click="visible = !visible"
    >
      Выполнено
    </el-button>
  </el-popover>
</template>
<script>
export default {
  name: 'TaskStatusControlsDone',
  props: {
    tooltipPlacement: { type: String, required: true },
    task: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    size: { type: String, default: 'small' }
  },
  data () {
    return {
      visible: false,
      model: {
        date_end: this.task.date_end,
        minutes: this.task.minutes_client || this.task.minutes_estimated || this.task.minutes_real
      },
      rules: {
        date_end: [
          { required: true, message: 'Обязательно для заполнения', trigger: 'change' }
        ],
        minutes: [
          { required: true, message: 'Обязательно для заполнения', trigger: 'blur', type: 'number', min: 1 }
        ]
      }
    }
  },
  mounted () {
    document.addEventListener('click', this.hidePopover)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.hidePopover)
  },
  methods: {
    hidePopover (e) {
      if ((!this.$refs.datePicker || !this.$refs.datePicker.pickerVisible) && !this.$el.contains(e.target)) {
        this.visible = false
      }
    },
    doneConfirm () {
      this.$refs.doneForm.validate(valid => {
        if (valid) {
          this.visible = false
          this.$emit('confirm', {
            ...this.model,
            id: this.task.id,
            status: 'done'
          })
        }
      })
    },
    cancelForm () {
      this.visible = false
      this.$refs.doneForm.resetFields()
    }
  }
}
</script>
