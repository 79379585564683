<template>
  <block-item-wrapper>
    <div class="title">
      <div class="left">
        {{ item.user_id | userNameById(userItems) }}
      </div>
      <div class="left small">
        {{ item.created_at | formatDateTime }}
      </div>
      <div
        v-for="(link, index) in links"
        :key="index"
      >
        <el-tooltip
          v-if="link.tooltip"
          :content="link.tooltip"
        >
          <el-link
            type="primary"
            icon="el-icon-link"
            size="mini"
            :href="link.href"
            class="history-link"
          >
            {{ link.title }}
          </el-link>
        </el-tooltip>
        <el-link
          v-else
          type="primary"
          icon="el-icon-link"
          size="mini"
          :href="link.href"
          class="history-link"
        >
          {{ link.title }}
        </el-link>
      </div>
    </div>
    <div
      class="description"
      v-html="item.description"
    />
  </block-item-wrapper>
</template>
<script>

import { userNameById, formatDateTime } from '@/filters'
import { mapGetters } from 'vuex'
import BlockItemWrapper from '@/components/blocks/BlockItemWrapper'

const makeTaskLinks = (item, isAdmin, projects, userId, showTaskLink) => {
  const type = isAdmin
    ? 'all'
    : (projects.find(project => project.id === item.entity.project_id && project.managers.includes(userId)) ? 'my' : 'manager')

  const href = `/tasks/${type}/${item.entity_id}`

  const links = showTaskLink ? [{ tooltip: item.entity.title, title: 'к задаче #' + item.entity_id, href }] : []

  switch (item.link_entity_code) {
    case 'comment':
      links.push({ title: 'к коментарию', href: href + `#comment-${item.link_entity_id}` })
      break
    case 'worktime':
      links.push({ title: 'к записи', href: href + `#worktime-${item.link_entity_id}` })
      break
  }

  return links
}

export default {
  name: 'BlockHistoryItem',
  components: { BlockItemWrapper },
  filters: { userNameById, formatDateTime },
  props: {
    item: { type: Object, required: true },
    showTaskLink: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters('users', ['userItems']),
    ...mapGetters('auth', ['role', 'userData']),
    ...mapGetters('projects', ['projectItems']),
    links () {
      switch (this.item.entity_code) {
        case 'task':
          return makeTaskLinks(this.item, this.role === 'admin', this.projectItems, this.userData.id, this.showTaskLink)
        case 'project':
          return [{ title: 'к проекту', href: '/projects/' + this.item.entity_id }]
        case 'user':
          return [{ title: 'к пользователю', href: '/users/' + this.item.entity_id }]
      }
      return []
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
    font-size: small;

    &:after {
        content: '';
        clear: both;
        height: 0;
        display: block;
    }

    & > div {
        float: left;
        margin-right: 10px;
        margin-bottom: 10px;
    }

  .history-link {
    margin: 0 0 0 15px;
  }
}
</style>
