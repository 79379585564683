<template>
  <el-form
    ref="form"
    v-loading="loading"
    :model="model"
    :rules="rules"
    label-width="200px"
  >
    <el-form-item
      label="ФИО"
      prop="name"
      :error="errors.name"
    >
      <el-input v-model="model.name" />
    </el-form-item>
    <el-form-item
      label="Самозанятый"
      prop="is_self_employed"
    >
      <el-checkbox v-model="model.is_self_employed" />
    </el-form-item>
    <el-form-item
      label="ИНН"
      prop="inn"
      :error="errors.inn"
    >
      <el-input v-model="model.inn" />
    </el-form-item>
    <el-form-item
      label="EMail"
      prop="email"
      :error="errors.email"
    >
      <el-input v-model="model.email" />
    </el-form-item>
    <el-form-item
      label="Телефон"
      prop="phone"
      :error="errors.phone"
    >
      <el-input v-model="model.phone" />
    </el-form-item>
    <el-form-item
      label="Роль"
      prop="role"
      :error="errors.role"
    >
      <el-select v-model="model.role">
        <el-option
          v-for="role in rolesOptions"
          :key="role.value"
          :label="role.label"
          :value="role.value"
        />
      </el-select>
    </el-form-item>
    <el-form-item
      label="Активен"
      prop="status"
    >
      <el-switch v-model="model.status" />
    </el-form-item>
    <el-form-item
      label="Пароль"
      prop="password"
      :error="errors.password"
    >
      <el-input
        v-model="model.password"
        show-password
      />
    </el-form-item>

    <el-form-item
      v-if="asManagerAt"
      label="Отвечает за проекты"
    >
      {{ asManagerAt }}
    </el-form-item>
    <el-form-item
      v-if="asWorkerAt"
      label="Работает над проектами"
    >
      {{ asWorkerAt }}
    </el-form-item>

    <el-form-item>
      <el-button
        type="primary"
        :disabled="loading"
        @click="save"
      >
        Сохранить
      </el-button>
      <el-button
        :disabled="loading"
        @click="cancel"
      >
        Отмена
      </el-button>
    </el-form-item>
  </el-form>
</template>
<script>

import roles from '@/data/roles'
import { mapActions, mapGetters } from 'vuex'
import FormMixin from '@/mixins/FormMixin'

export default {
  name: 'FormUser',
  mixins: [FormMixin],
  props: {
    value: { type: Object, default: null }
  },
  data () {
    return {
      model: {
        id: null,
        status: true,
        email: '',
        password: '',
        name: '',
        phone: '',
        inn: '',
        is_self_employed: false,
        role: 'worker'
      },
      errors: {
        email: '',
        password: '',
        phone: '',
        inn: '',
        name: '',
        role: ''
      },
      rules: {
        email: [
          { required: true, message: 'Обязательно для заполнения', trigger: 'blur' },
          { type: 'email', message: 'Некорректный email', trigger: 'blur' }
        ],
        password: [
          { required: this.value === null, message: 'Обязательно для заполнения', trigger: 'blur' }
        ],
        name: [
          { required: true, message: 'Обязательно для заполнения', trigger: 'blur' }
        ],
        role: [
          { required: true, message: 'Обязательно для заполнения', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters('projects', ['projectItems']),
    asManagerAt () {
      return this.model.id
        ? this.projectItems
          .filter(project => project.managers.includes(this.model.id))
          .map(project => project.title)
          .join(', ')
        : null
    },
    asWorkerAt () {
      return this.model.id
        ? this.projectItems
          .filter(project => project.workers.includes(this.model.id))
          .map(project => project.title)
          .join(', ')
        : null
    },
    rolesOptions () {
      return Object.keys(roles).map(key => {
        return {
          value: key,
          label: roles[key]
        }
      })
    }
  },
  methods: {
    ...mapActions('users', ['saveUser']),
    save () {
      this.saveWrapper(this.saveUser, this.model)
    },
    updateData () {
      if (this.value) {
        Object.assign(this.model, this.value)
      } else {
        this.$refs.form.resetFields()
        this.model.id = null
      }
      this.rules.password[0].required = this.value === null
    }
  }
}
</script>
