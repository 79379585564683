<template>
  <el-main class="page-login">
    <h2>Вход</h2>
    <form-login />
  </el-main>
</template>
<script>

import FormLogin from '@/components/forms/FormLogin'

export default {
  name: 'PageLogin',
  components: { FormLogin }
}
</script>
<style lang="scss" scoped>
.page-login {
    h2 {
        text-align: center;
    }

    margin: auto;
    width: 400px;
}
</style>
