<template>
  <el-menu
    mode="horizontal"
    background-color="#545c64"
    text-color="#fff"
    active-text-color="#ffd04b"
    router
    :default-active="$route.path"
  >
    <el-menu-item index="/">
      Dashboard
    </el-menu-item>

    <el-menu-item
      v-if="isAccountant"
      index="/act"
    >
      Акты
    </el-menu-item>

    <el-menu-item
      v-if="isAdmin"
      index="/tasks/all"
    >
      Все задачи
    </el-menu-item>
    <el-menu-item
      v-if="isManager"
      index="/tasks/manager"
    >
      Мои проекты
    </el-menu-item>
    <el-menu-item
      v-if="!isAccountant"
      index="/tasks/my"
    >
      Мои задачи
    </el-menu-item>

    <el-menu-item
      v-if="isAdmin"
      index="/projects"
    >
      Проекты
    </el-menu-item>
    <el-menu-item
      v-if="isAdmin"
      index="/users"
    >
      Пользователи
    </el-menu-item>
    <el-menu-item
      v-if="isManager"
      index="/report"
    >
      Отчет
    </el-menu-item>

    <el-submenu
      index="2"
      class="submenu-right"
    >
      <template #title>
        {{ userData.name }}
      </template>
      <el-menu-item index="/profile">
        Профиль
      </el-menu-item>
      <el-menu-item @click="logout">
        Выйти
      </el-menu-item>
    </el-submenu>
  </el-menu>
</template>
<script>

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TopNavbar',
  computed: {
    ...mapGetters('auth', ['userData', 'role']),
    ...mapGetters('projects', ['projectItems']),
    isAdmin () {
      return this.role === 'admin'
    },
    isAccountant () {
      return this.role === 'accountant'
    },
    isManager () {
      return this.projectItems.find(project => project.managers.includes(this.userData.id)) !== undefined
    }
  },
  mounted () {
    this.loadProjects()
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('projects', ['loadProjects'])
  }
}
</script>
<style lang="scss">
.el-menu--horizontal>.el-submenu.submenu-right {
    float: right;
}
</style>
