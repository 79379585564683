<template>
  <block-item-wrapper
    :id="elementId"
    :highlight="$route.hash === '#' + elementId"
  >
    <div class="title">
      <el-link :href="'#' + elementId">
        <el-icon name="s-comment" />
        <span>{{ item.user_id | userNameById(userItems) }}</span>
        <span
          class="small"
          style="margin-left: 10px;"
        >
          {{ item.updated_at | formatDateTime }}
        </span>
      </el-link>
      <el-link
        v-if="item.user_id === userData.id"
        type="primary"
        icon="el-icon-edit"
        size="mini"
        @click="$emit('edit', item)"
      >
        Редактировать
      </el-link>
    </div>
    <custom-editor-view :value="item.text" />
  </block-item-wrapper>
</template>
<script>

import { formatDateTime, userNameById } from '@/filters'
import { mapGetters } from 'vuex'
import BlockItemWrapper from '@/components/blocks/BlockItemWrapper'

export default {
  name: 'BlockCommentsItem',
  components: { BlockItemWrapper },
  filters: {
    userNameById,
    formatDateTime
  },
  props: {
    item: { type: Object, required: true }
  },
  computed: {
    ...mapGetters('auth', ['userData']),
    ...mapGetters('users', ['userItems']),
    elementId () {
      return `comment-${this.item.id}`
    }
  }
}

</script>
<style lang="scss" scoped>
.small {
    font-size: small;
}
.title {
    margin-bottom: 20px;
    & > * {
        margin-right: 20px;
    }
}
</style>
