<template>
  <el-main>
    <h1>403</h1>
    <p>Доступ запрещен</p>
    <p>
      <el-link
        type="primary"
        icon="el-icon-back"
        @click="goBack"
      >
        вернуться
      </el-link>
    </p>
  </el-main>
</template>
<script>
export default {
  name: 'PageAccessDenied',
  methods: {
    goBack () {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    }
  }
}
</script>
