<template>
  <section>
    <h1>
      <span style="vertical-align: middle; margin-right: 20px;">Отчет за</span>
      <date-select
        v-model="currentDate"
        :type="typeFilter"
        :min-width="220"
        lock-max
      />
    </h1>

    <el-form
      v-if="!loading"
      inline
      label-position="top"
      size="small"
    >
      <el-form-item label="Тип отчета">
        <el-select v-model="typeFilter">
          <el-option
            label="Месяц"
            value="month"
          />
          <el-option
            label="Неделя"
            value="week"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Исполнители">
        <el-select
          v-model="workerFilter"
          clearable
          filterable
        >
          <el-option
            v-for="worker in availableUsers"
            :key="worker.id"
            :label="worker.name"
            :value="worker.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Проекты">
        <el-select
          v-model="projectFilter"
          clearable
          filterable
        >
          <el-option
            v-for="project in filteredProjects"
            :key="project.id"
            :label="project.title"
            :value="project.id"
          />
        </el-select>
      </el-form-item>
    </el-form>

    <table-report
      :items="items"
      :loading="loading"
      :users="availableUsers"
      :worker-id="workerFilter"
      :projects="availableProjects"
      :project-id="projectFilter"
      @select="selectFilter"
    />
  </section>
</template>
<script>
import moment from 'moment'
import TableReport from '@/components/tables/TableReport'
import api from '@/api/reports'
import ReportFormMixin from '@/mixins/ReportFormMixin'

export default {
  name: 'PageReportView',
  components: { TableReport },
  mixins: [ReportFormMixin],
  data () {
    return {
      items: [],
      loading: true
    }
  },
  computed: {
    from () {
      return moment(this.currentDate).startOf(this.typeFilter).format('YYYY-MM-DD')
    },
    to () {
      return moment(this.currentDate).endOf(this.typeFilter).format('YYYY-MM-DD')
    },
    typeFilter: {
      set (type) {
        this.go({ type })
      },
      get () {
        return this.$route.query.type ? this.$route.query.type.toString() : 'month'
      }
    },
    typeText () {
      return this.typeFilter === 'month' ? 'месяц' : 'неделю'
    },
    workerFilter: {
      set (worker) {
        if (
          worker &&
          this.projectFilter &&
          this.availableProjects.find(project => project.id === this.projectFilter && project.workers.includes(worker)) === undefined
        ) {
          this.go({ worker, project: '' })
        } else {
          this.go({ worker })
        }
      },
      get () {
        return this.$route.query.worker ? parseInt(this.$route.query.worker.toString()) : null
      }
    },
    projectFilter: {
      set (project) {
        this.go({ project })
      },
      get () {
        return this.$route.query.project ? parseInt(this.$route.query.project.toString()) : null
      }
    },
    filteredProjects () {
      return this.workerFilter ? this.availableProjects.filter(project => project.workers.includes(this.workerFilter)) : this.availableProjects
    }
  },
  watch: {
    from () {
      this.load()
    },
    to () {
      this.load()
    }
  },
  methods: {
    load () {
      this.loading = true
      api.load({
        from: this.from,
        to: this.to
      }).then(items => {
        this.items = items
      }).finally(() => {
        this.loading = false
      })
    },
    selectFilter (value) {
      this[value.entity + 'Filter'] = value.id
    }
  }
}
</script>
