<template>
  <el-card>
    <div slot="header">
      <span style="margin-right: 20px;">История</span>
      <el-checkbox v-model="showMyActions">
        Показать мои действия
      </el-checkbox>
    </div>
    <div class="infinite-list-wrapper">
      <div
        v-infinite-scroll="load"
        class="list"
        infinite-scroll-disabled="disabled"
      >
        <block-history-item
          v-for="item in items"
          :key="item.id"
          :item="item"
          show-task-link
        />
      </div>
      <p v-if="loading">
        Загрузка...
      </p>
      <p v-if="noMore">
        [ Конец истории ]
      </p>
    </div>
  </el-card>
</template>
<script>
import api from '@/api/widgets'
import BlockHistoryItem from '@/components/blocks/BlockHistoryItem'

export default {
  name: 'WidgetHistory',
  components: { BlockHistoryItem },
  props: {
    role: { type: String, required: true }
  },
  data () {
    return {
      items: [],
      loading: true,
      noMore: false,
      showMyActions: false,
      page: 1
    }
  },
  computed: {
    disabled () {
      return this.loading || this.noMore
    }
  },
  watch: {
    showMyActions () {
      this.load(true)
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    load (reload = false) {
      this.loading = true
      if (reload) {
        this.page = 1
      }
      api.loadHistory({
        showMyActions: this.showMyActions,
        page: this.page
      }).then(data => {
        if (reload) {
          this.items = data.items
        } else {
          this.items = [...this.items, ...data.items]
          this.page++
        }
        this.noMore = this.items.length >= data.count
      }).catch(() => {
        this.$notify({
          title: 'Ошибка',
          message: 'Невозможно загрузить историю',
          type: 'error'
        })
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.infinite-list-wrapper {
  overflow-y: auto;
  max-height: 400px;
}
</style>
