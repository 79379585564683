<template>
  <div>
    <el-input
      v-if="hideTooltip"
      v-model="intermediateValue"
      @blur="setValue"
    />
    <el-tooltip
      v-else
      effect="dark"
      content="h - часы, m - минуты, ввод в произвольной форме на русском или английском"
      placement="top"
    >
      <el-input
        v-model="intermediateValue"
        @blur="setValue"
      />
    </el-tooltip>
  </div>
</template>
<script>
export default {
  name: 'InputHours',
  props: {
    value: { type: [String, Number], default: '' },
    hideTooltip: { type: Boolean, default: false }
  },
  data () {
    return {
      intermediateValue: ''
    }
  },
  watch: {
    value () {
      this.updateIntermediateValue()
    }
  },
  mounted () {
    this.updateIntermediateValue()
  },
  methods: {
    setValue () {
      const str = this.intermediateValue
        .replace(/,/g, '.')
        .replace(/ч/ig, 'h')
        .replace(/р/ig, 'h')
        .replace(/x/ig, 'h')
        .replace(/ь/ig, 'm')
        .replace(/v/ig, 'm')
        .replace(/м/ig, 'm')
        .replace(/д/ig, 'd')
        .replace(/н/ig, 'w')
        .replace(/[^\dmhdw.]/ig, '') || '0'

      const weeks = parseFloat((/([\d.]+)w/g.exec(str) || [0, 0])[1])
      const days = parseFloat((/([\d.]+)d/g.exec(str) || [0, 0])[1])
      const hours = parseFloat((/([\d.]+)h/g.exec(str) || [0, 0])[1])
      const minutes = parseInt((/(\d+)m/.exec(str) || [0, 0])[1])

      this.$emit('input', Math.ceil((weeks * 5 * 8 * 60) + (days * 8 * 60) + (hours * 60) + minutes || str * 60) || 0)
      this.updateIntermediateValue()
    },
    updateIntermediateValue () {
      const result = []
      const hours = Math.floor(this.value / 60)
      const minutes = Math.ceil(this.value % 60)

      if (hours) {
        result.push(hours + 'h')
      }

      if (minutes) {
        result.push(minutes + 'm')
      }
      this.intermediateValue = result.join(' ')
    }
  }
}
</script>
