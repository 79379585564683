<template>
  <el-table
    :data="tableData"
    :span-method="spanMethod"
  >
    <el-table-column
      label="Исполнитель"
      width="200"
      prop="user"
    />
    <el-table-column
      label="Проект"
      width="200"
      prop="project"
    />
    <el-table-column
      v-for="column in weekColumns"
      :key="column"
      :label="column | toDay"
      width="75"
    >
      <el-popover
        slot-scope="scope"
        placement="top-start"
        :title="column | formatDate"
        trigger="hover"
      >
        <div
          v-for="task in scope.row[column].tasks"
          :key="task.id"
        >
          <el-link
            type="primary"
            :href="`/tasks/${taskTab}/${task.id}`"
          >
            {{ task.title }} #{{ task.id }}
          </el-link> - {{ task.hours }}
        </div>
        <span slot="reference">{{ scope.row[column].total }}</span>
      </el-popover>
    </el-table-column>
    <el-table-column
      label="ИТОГО за неделю"
      prop="week"
      width="150"
    />
    <el-table-column
      :label="monthTitle"
      prop="month"
      width="150"
    />
  </el-table>
</template>
<script>

import moment from 'moment'
import { toHours, formatDate } from '@/filters'
import { mapGetters } from 'vuex'
import { groupBy } from 'lodash'

export default {
  name: 'WidgetWorktimeTableExpanded',
  filters: {
    toDay: (value) => {
      return moment(value, 'YYYY-MM-DD').format('DD')
    },
    formatDate,
    toHours
  },
  props: {
    userId: { type: Number, required: true },
    users: { type: Array, required: true },
    dateObject: { type: Date, required: true },
    model: { type: Object, required: true },
    role: { type: String, required: true }
  },
  computed: {
    ...mapGetters('projects', ['projectItems']),
    monthTitle () {
      return 'ИТОГО за ' + moment(this.dateObject).startOf('w').format('MMMM')
    },
    weekColumns () {
      const result = []
      const obj = moment(this.dateObject).startOf('w')
      for (let i = 0; i < 7; i++) {
        result.push(obj.format('YYYY-MM-DD'))
        obj.add(1, 'd')
      }
      return result
    },
    taskTab () {
      return this.role === 'admin' ? 'all' : 'my'
    },
    tableData () {
      // Подготавливаем массив строк (по пользователям) с предваритемльными данными
      const rows = this.model.month.map(row => {
        return {
          user: this.users.find(user => user.id === row.user_id),
          project: this.projectItems.find(item => item.id === row.project_id),
          month: toHours(row.minutes),
          items: []
        }
      })

      // Добавляем в массив строк временные данные по дням
      this.model.days.forEach(day => {
        const row = rows.find(row => row.user.id === day.user_id && day.task.project_id === row.project.id)
        // На случай если день не попадет в инфу по месяцам
        if (row === undefined) {
          rows.push({
            user: this.users.find(user => user.id === day.user_id),
            project: this.projectItems.find(project => project.id === day.task.project_id),
            month: 0,
            items: [day]
          })
        } else {
          row.items.push(day)
        }
      })

      rows.sort((a, b) => a.user.name.localeCompare(b.user.name)).sort(a => a.user.id === this.userId ? -1 : 1)

      // Обрабатываем массив строк
      return rows.map(row => {
        const days = {}
        let weekTotal = 0

        // Раскидываем данные по дням
        row.items.forEach(item => {
          if (days[item.day] === undefined) {
            days[item.day] = [item]
          } else {
            days[item.day].push(item)
          }
        })

        // Приводим дни к нужному виду
        Object.keys(days).forEach(day => {
          let total = 0
          const tasks = []
          days[day].forEach(item => {
            total += item.minutes
            weekTotal += item.minutes
            tasks.push({
              id: item.task.id,
              title: item.task.title,
              hours: toHours(item.minutes)
            })
          })
          days[day] = {
            total: toHours(total),
            tasks
          }
        })

        // Заполняем пустые даты
        this.weekColumns.forEach(day => {
          if (days[day] === undefined) {
            days[day] = {
              total: '',
              tasks: []
            }
          }
        })

        return {
          user_id: row.user.id,
          user: row.user.name,
          project: row.project.title,
          ...days,
          week: toHours(weekTotal),
          month: row.month
        }
      })
    },
    spanMap () {
      const groups = groupBy(this.tableData, row => row.user_id)
      Object.keys(groups).forEach(key => {
        groups[key] = groups[key].length
      })
      return this.tableData.map((row, index) => {
        return index === 0 || this.tableData[index - 1].user_id !== row.user_id ? groups[row.user_id] : 0
      })
    }
  },
  methods: {
    spanMethod ({ columnIndex, rowIndex }) {
      if (columnIndex === 0) {
        return [this.spanMap[rowIndex], 1]
      }
    }
  }
}
</script>
