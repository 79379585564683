<template>
  <div
    v-if="model"
    v-loading="loadingPartial"
  >
    <div v-if="loading">
      Загрузка...
    </div>
    <template v-else>
      <el-container>
        <el-main>
          <el-page-header
            :content="model.title + ' #' + model.id"
            title="К списку задач"
            @back="goBack"
          />

          <p>
            <el-button
              v-if="canAddTime"
              type="primary"
              size="small"
              :disabled="loadingPartial"
              @click="addWorktime"
            >
              Внести время
            </el-button>
            <el-button
              v-if="canEdit"
              type="primary"
              size="small"
              :disabled="loadingPartial"
              @click="popupName = 'edit'"
            >
              Редактировать
            </el-button>
            <task-status-controls
              v-model="model"
              :loading.sync="loadingPartial"
              @change="reloadHistory"
            />
          </p>

          <p v-if="model.link">
            <el-link
              type="primary"
              target="_blank"
              :href="model.link"
            >
              Ссылка на задачу
            </el-link>
          </p>

          <h4>Описание задачи</h4>

          <el-divider />

          <custom-editor-view :value="model.description" />

          <el-divider />

          <p>
            <el-button
              v-if="canAddTime"
              type="primary"
              :disabled="loadingPartial"
              size="small"
              @click="addWorktime"
            >
              Внести время
            </el-button>
            <el-button
              v-if="canComment"
              type="primary"
              :disabled="loadingPartial"
              size="small"
              @click="addComment"
            >
              Добавить коментарий
            </el-button>
          </p>

          <el-tabs v-model="activeTab">
            <el-tab-pane
              label="Все сообщения"
              name="messages"
            >
              <block-messages
                :items="messages"
                :is-manager="userIsManager"
                @editComment="editComment"
                @editWorktime="editWorktime"
                @deleteWorktime="deleteWorktime"
              />
            </el-tab-pane>
            <el-tab-pane
              label="Комментарии"
              name="comments"
            >
              <block-comments
                :items="comments"
                @edit="editComment"
              />
            </el-tab-pane>
            <el-tab-pane
              label="Рабочее время"
              name="worktime"
            >
              <block-worktime
                :is-manager="userIsManager"
                :items="worktime"
                :can-edit="canAddTime"
                @edit="editWorktime"
                @delete="deleteWorktime"
              />
            </el-tab-pane>
            <el-tab-pane
              label="История"
              name="history"
            >
              <block-history
                ref="historyBlock"
                entity-code="task"
                :entity-id="taskId"
              />
            </el-tab-pane>
          </el-tabs>
        </el-main>
        <el-aside width="300px">
          <el-card>
            <p>Статус: {{ model.status | taskStatusTitle }}</p>
            <p>Проект: {{ model.project_id | projectNameById(projectItems) }}</p>
            <p v-if="model.parent">
              Основная задача: <el-link
                :href="`/tasks/${ model.parent.id }`"
                type="primary"
              >
                {{ model.parent.title }}
              </el-link>
            </p>
            <p>Дата начала: {{ model.date_start | formatDate }}</p>
            <p>Дата завершения: {{ model.date_end | formatDate }}</p>
            <p>Оценка: {{ model.minutes_estimated | toHours }}</p>
            <p>Затрачено времени: {{ model.minutes_real | toHours }}</p>
            <p v-if="showTimeClient">
              Время для клиента: {{ model.minutes_client | toHours }}
            </p>
            <p v-if="project.link">
              <el-link
                type="primary"
                target="_blank"
                :href="project.link"
              >
                Ссылка на проект
              </el-link>
            </p>
          </el-card>
        </el-aside>
      </el-container>
    </template>

    <el-dialog
      title="Указание рабочего времени"
      :visible="popupName === 'worktime'"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <form-worktime
        :task-id="taskId"
        :check-refresh="popupName === 'worktime'"
        :value="worktimeToEdit"
        :is-manager="userIsManager"
        :user-items="userItems"
        :project="project"
        @cancel="closePopup"
        @save="savePopup"
      />
    </el-dialog>
    <el-dialog
      title="Комментарий"
      :visible="popupName === 'comment'"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <form-comment
        :task-id="taskId"
        :check-refresh="popupName === 'comment'"
        :value="commentToEdit"
        @cancel="closePopup"
        @save="savePopup"
      />
    </el-dialog>

    <el-dialog
      title="Редактирование задачи"
      :visible="popupName === 'edit'"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <form-task
        :check-refresh="popupName === 'edit'"
        :value="model"
        :user-items="userItems"
        :project-items="availableProjects"
        @cancel="closePopup"
        @save="savePopup"
      />
    </el-dialog>
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
import { toHours, formatDate, projectNameById, taskStatusTitle } from '@/filters'
import api from '@/api/tasks'
import FormWorktime from '../forms/FormWorktime'
import FormComment from '../forms/FormComment'
import BlockComments from '../blocks/BlockComments'
import BlockHistory from '../blocks/BlockHistory'
import CustomEditorView from '@/components/global/CustomEditorView'
import FormTask from '@/components/forms/FormTask'
import TaskStatusControls from '@/components/blocks/TaskStatusControls'
import BlockMessages from '@/components/blocks/BlockMessages'
import BlockWorktime from '@/components/blocks/BlockWorktime'

export default {
  name: 'PageTask',
  filters: {
    toHours,
    formatDate,
    projectNameById,
    taskStatusTitle
  },
  components: {
    BlockWorktime,
    BlockMessages,
    TaskStatusControls,
    FormTask,
    CustomEditorView,
    FormWorktime,
    FormComment,
    BlockComments,
    BlockHistory
  },
  data () {
    return {
      model: {
        title: '',
        description: '',
        minutes_real: null,
        minutes_client: null,
        minutes_estimated: null,
        date_start: null,
        date_end: null,
        parent: null,
        status: null,
        project_id: null,
        link: null
      },
      comments: [],
      worktime: [],
      loading: true,
      loadingPartial: false,
      popupName: null,
      commentToEdit: null,
      worktimeToEdit: null
    }
  },
  computed: {
    ...mapGetters('projects', ['projectItems']),
    ...mapGetters('auth', ['userData', 'role']),
    ...mapGetters('users', ['userItems']),
    hashTab () {
      return this.$route.hash.substr(1).split('-').shift()
    },
    activeTab: {
      get () {
        return ['comments', 'worktime', 'history', 'messages'].includes(this.hashTab) ? this.hashTab : 'messages'
      },
      set (tab) {
        if (tab !== this.hashTab) {
          this.$router.push('#' + tab)
        }
      }
    },
    taskId () {
      return parseInt(this.$route.params.id)
    },
    userIsManager () {
      return this.role === 'admin' || (this.project && this.project.managers.includes(this.userData.id))
    },
    project () {
      return this.projectItems.find(project => project.id === this.model.project_id)
    },
    canAddTime () {
      return this.canEdit
    },
    canComment () {
      return this.model.status !== 'archive'
    },
    canEdit () {
      return ['work', 'test'].includes(this.model.status)
    },
    showTimeClient () {
      return this.userIsManager
    },
    availableProjects () {
      return this.projectItems.filter(project => !project.is_archive)
    },
    messages () {
      return [...this.comments.map(item => { return { ...item, type: 'comment' } }), ...this.worktime.map(item => { return { ...item, type: 'worktime' } })].sort((a, b) => {
        return (new Date(a.updated_at)) < (new Date(b.updated_at)) ? 1 : -1
      })
    }
  },
  mounted () {
    this.loadUsers().then(() => {
      this.loadProjects().then(() => {
        this.loadData().then(() => {
          let tabName = 'all'
          if (this.model.workers.includes(this.userData.id)) {
            tabName = 'my'
          } else if (this.projectItems.find(project => project.id === this.model.project_id).managers.includes(this.userData.id)) {
            tabName = 'manager'
          }
          if (tabName !== this.$route.params.type) {
            this.$router.replace(`/tasks/${tabName}/${this.taskId}${this.$route.hash}`)
          }
          if (this.$route.hash) {
            if (this.$route.hash.indexOf('#worktime') === 0) {
              this.activeTab = 'worktime'
            }
            this.$nextTick(() => {
              const element = document.getElementById(this.$route.hash.substr(1))
              if (element) {
                element.scrollIntoView()
              }
            })
          }
        })
      })
    })
  },
  methods: {
    ...mapActions('projects', ['loadProjects']),
    ...mapActions('users', ['loadUsers']),
    reloadHistory () {
      this.$refs.historyBlock.reload()
    },
    loadData () {
      this.loading = true
      return api.load(this.taskId).then(data => {
        this.model = data.task
        this.comments = data.comments
        this.worktime = data.worktime
      }).catch(() => {
        this.$notify({
          title: 'Ошибка',
          message: 'Ошибка загрузки данных',
          type: 'error'
        })
      }).finally(() => {
        this.loading = false
      })
    },
    closePopup () {
      this.popupName = null
    },
    savePopup () {
      this.closePopup()
      this.loadData()
    },
    goBack () {
      this.$router.push('/tasks/' + this.$route.params.type)
    },
    addComment () {
      this.commentToEdit = null
      this.popupName = 'comment'
    },
    addWorktime () {
      this.worktimeToEdit = null
      this.popupName = 'worktime'
    },
    editWorktime (model) {
      this.worktimeToEdit = model
      this.popupName = 'worktime'
    },
    deleteWorktime (model) {
      this.loadingPartial = true
      model.description = '<em>Запись удалена</em>'
      model.minutes = 0
      api.saveTime(model).then(() => {
        this.loadData()
      }).catch(() => {
        this.$notify({
          title: 'Ошибка',
          message: 'Невозможно отправить данные',
          type: 'error'
        })
      }).finally(() => {
        this.loadingPartial = false
      })
    },
    editComment (model) {
      this.commentToEdit = model
      this.popupName = 'comment'
    }
  }
}
</script>
