<template>
  <div>
    <template v-for="item in items">
      <block-comments-item
        v-if="item.type === 'comment'"
        :key="item.id"
        :item="item"
        @edit="v => { $emit('editComment', v) }"
      />
      <block-worktime-item
        v-if="item.type === 'worktime'"
        :key="item.id"
        :item="item"
        :is-manager="isManager"
        @edit="v => { $emit('editWorktime', v) }"
        @delete="v => { $emit('deleteWorktime', v) }"
      />
    </template>
  </div>
</template>
<script>

import BlockCommentsItem from '@/components/blocks/BlockCommentsItem'
import BlockWorktimeItem from '@/components/blocks/BlockWorktimeItem'

export default {
  name: 'BlockMessages',
  components: {
    BlockWorktimeItem,
    BlockCommentsItem
  },
  props: {
    isManager: { type: Boolean, required: true },
    items: { type: Array, required: true }
  }
}

</script>
