import PageDashboard from '@/components/pages/PageDashboard'
import IndexUser from '@/components/pages/IndexUser'
import IndexProject from '@/components/pages/IndexProject'
import PageProfile from '@/components/pages/PageProfile'
import IndexTaskAll from '@/components/pages/IndexTaskAll'
import IndexTaskManager from '@/components/pages/IndexTaskManager'
import IndexTaskMy from '@/components/pages/IndexTaskMy'
import PageTask from '@/components/pages/PageTask'
import PageNotFound from '@/components/pages/PageNotFound'
import PageAccessDenied from '@/components/pages/PageAccessDenied'

import store from '@/store'
import PageReport from '@/components/pages/PageReport'
import PageAct from '@/components/pages/PageAct.vue'

const checkIsAdmin = (to, from, next) => {
  if (store.state.auth.user.role === 'admin') {
    next()
  } else {
    next('/403')
  }
}
const checkIsAccountant = (to, from, next) => {
  if (['admin', 'accountant'].includes(store.state.auth.user.role)) {
    next()
  } else {
    next('/403')
  }
}

export default [
  { path: '/', component: PageDashboard },
  { path: '/act', component: PageAct, beforeEnter: checkIsAccountant },
  { path: '/users', component: IndexUser, beforeEnter: checkIsAdmin },
  { path: '/projects', component: IndexProject, beforeEnter: checkIsAdmin },
  { path: '/profile', component: PageProfile },
  { path: '/tasks/all', component: IndexTaskAll, beforeEnter: checkIsAdmin },
  { path: '/tasks/manager', component: IndexTaskManager },
  { path: '/tasks/my', component: IndexTaskMy, alias: '/tasks' },
  { path: '/tasks/:type/:id', component: PageTask },
  { path: '/report', component: PageReport },
  { path: '/403', component: PageAccessDenied },
  { path: '*', component: PageNotFound }
]
