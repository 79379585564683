<template>
  <el-form
    ref="form"
    v-loading="loading"
    :model="model"
    :rules="rules"
    label-position="top"
  >
    <el-form-item
      v-if="isManager"
      label="Исполнитель"
      :error="errors.user_id"
      prop="user_id"
    >
      <el-select
        v-model="model.user_id"
        filterable
      >
        <el-option
          v-for="worker in projectWorkers"
          :key="worker.id"
          :label="worker.name"
          :value="worker.id"
        />
      </el-select>
    </el-form-item>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item
          label="Время"
          :error="errors.minutes"
          prop="minutes"
        >
          <input-hours v-model="model.minutes" />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item
          label="Дата"
          :error="errors.day"
          prop="day"
        >
          <el-date-picker
            v-model="model.day"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            format="dd.MM.yyyy"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item
      label="Описание"
      :error="errors.description"
      prop="description"
    >
      <custom-editor
        ref="editor"
        v-model="model.description"
      />
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        :disabled="loading"
        @click="save"
      >
        Сохранить
      </el-button>
      <el-button
        :disabled="loading"
        @click="cancel"
      >
        Отмена
      </el-button>
    </el-form-item>
  </el-form>
</template>
<script>

import api from '@/api/tasks'
import FormMixin from '@/mixins/FormMixin'
import moment from 'moment'
import { checkDateCondition } from '@/utils/funcs'

export default {
  name: 'FormWorktime',
  mixins: [FormMixin],
  props: {
    isManager: { type: Boolean, required: true },
    taskId: { type: Number, required: true },
    project: { type: Object, required: true },
    userItems: { type: Array, required: true }
  },
  data () {
    return {
      model: {
        id: null,
        minutes: 0,
        description: '',
        user_id: null,
        day: moment().format('YYYY-MM-DD')
      },
      errors: {
        user_id: '',
        minutes: '',
        description: '',
        day: '',
        task_id: ''
      },
      rules: {
        user_id: [
          { required: true, message: 'Укажите исполнителя', trigger: 'change' }
        ],
        minutes: [
          { required: true, message: 'Укажите кол-во часов', trigger: 'blur', type: 'number', min: 1 },
          { message: 'Суммарное затраченное время на указанную дату превышает 24 часа', trigger: 'blur', type: 'number', max: 0 }
        ],
        description: [
          {
            required: true,
            validator: (rule, value, callback) => {
              const tmpEl = document.createElement('DIV')
              tmpEl.innerHTML = value
              if (tmpEl.innerText.trim().length === 0) {
                callback(new Error('Описание обязательно'))
              }
              callback()
            },
            message: 'Описание обязательно'
          }
        ],
        day: [
          { required: true, message: 'Обязательно для заполнения', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    pickerOptions () {
      return {
        firstDayOfWeek: 1,
        disabledDate: date => !((this.isManager || checkDateCondition(date)) && date < new Date())
      }
    },
    projectWorkers () {
      return this.project ? this.userItems.filter(user => user.status && this.project.workers.includes(user.id)) : []
    }
  },
  watch: {
    'model.day' (value) {
      if (value) {
        this.loadTimeAvailable(value)
      }
    },
    'model.description' () {
      this.$refs.form.validateField('description')
    }
  },
  mounted () {
    this.loadTimeAvailable(this.model.day)
  },
  methods: {
    save () {
      this.saveWrapper(api.saveTime, { ...this.model, task_id: this.taskId, user_id: this.isManager ? this.model.user_id : null })
    },
    updateData () {
      if (this.value) {
        this.model.id = this.value.id
        this.model.minutes = this.value.minutes
        this.model.description = this.value.description
        this.model.day = this.value.day
        this.model.user_id = this.value.user_id
      } else {
        this.model.id = null
        this.model.user_id = null
        this.$refs.form.resetFields()
      }
    },
    loadTimeAvailable (day) {
      this.loading = true
      api.todayTimeAvailable(day)
        .then(minutes => {
          this.rules.minutes[1].max = minutes
          if (this.model.minutes) {
            this.$refs.form.validateField('minutes')
          }
        }).catch(() => {
          this.$notify({
            title: 'Ошибка',
            message: 'Невозможно загрузить данные',
            type: 'error'
          })
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
