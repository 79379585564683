<template>
  <el-main>
    <form-profile />
  </el-main>
</template>
<script>

import FormProfile from '@/components/forms/FormProfile'

export default {
  name: 'PageProfile',
  components: { FormProfile }
}
</script>
