<template>
  <el-form
    ref="form"
    v-loading="loading"
    :model="model"
    :rules="rules"
    label-width="100px"
  >
    <el-form-item
      label="EMail"
      prop="email"
    >
      <el-input
        v-model="model.email"
        type="email"
      />
    </el-form-item>
    <el-form-item
      label="Пароль"
      :error="errors.password"
      prop="password"
    >
      <el-input
        v-model="model.password"
        show-password
      />
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        :disabled="loading"
        @click="doLogin"
      >
        Войти
      </el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'FormLogin',
  data () {
    return {
      model: {
        email: '',
        password: ''
      },
      errors: {
        password: ''
      },
      rules: {
        email: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { type: 'email', message: 'Некорректный email', trigger: 'blur' }
        ],
        password: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
        ]
      },
      loading: false
    }
  },
  methods: {
    ...mapActions('auth', ['login']),
    doLogin () {
      this.errors.password = ''
      this.$refs.form.validate(valid => {
        if (valid) {
          this.login({ email: this.model.email, password: this.model.password })
            .catch(message => {
              if (message.errors) {
                Object.keys(message.errors).forEach(key => {
                  if (this.errors[key] === undefined) {
                    console.error('Необработанная ощибка валидации', key)
                  } else {
                    this.errors[key] = message.errors[key].join('<br>')
                  }
                })
              } else {
                this.$notify({
                  title: 'Ошибка',
                  message: 'Ошибка при отправке данных',
                  type: 'error'
                })
              }
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          return false
        }
      })
    }
  }
}
</script>
