import api from '@/api/projects'

const state = {
  items: []
}

const getters = {
  projectItems: state => state.items
}

const actions = {
  loadProjects ({ commit }) {
    return api.load().then(data => {
      commit('setProjects', data)
      return data
    })
  },
  saveProject ({ commit }, model) {
    return api.save(model).then(data => {
      commit(!model.id ? 'addProject' : 'updateProject', data)
      return data
    })
  },
  setArchive ({ commit }, { id, isArchive }) {
    return api.setArchive({ id, is_archive: isArchive }).then(data => {
      commit('updateProject', data)
      return data
    })
  }
}

const mutations = {
  setProjects (state, data) {
    state.items = data
  },
  updateProject (state, data) {
    state.items.splice(state.items.findIndex(item => item.id === data.id), 1)
    state.items.unshift({ ...data })
  },
  addProject (state, data) {
    state.items.unshift({ ...data })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
