<template>
  <el-card v-loading="loading">
    <div slot="header">
      <span>Затраченное время</span>
    </div>
    <el-form
      inline
      label-position="right"
      size="mini"
    >
      <el-button
        size="mini"
        icon="el-icon-back"
        @click="goPrev"
      >
        На неделю назад
      </el-button>
      <el-button
        size="mini"
        :disabled="isNextButtonDisabled"
        @click="goNext"
      >
        На неделю вперед <i class="el-icon-arrow-right el-icon-right" />
      </el-button>

      <el-switch
        v-model="expanded"
        style="margin-left: 20px;"
        active-text="Разбить время по проектам"
      />
    </el-form>
    <widget-worktime-table-expanded
      v-if="expanded"
      :date-object="dateObject"
      :model="model"
      :users="users"
      :role="role"
      :user-id="userId"
    />
    <widget-worktime-table-simple
      v-else
      :date-object="dateObject"
      :model="model"
      :users="users"
      :role="role"
      :user-id="userId"
    />
  </el-card>
</template>
<script>

import api from '@/api/widgets'
import moment from 'moment'
import WidgetWorktimeTableSimple from './WidgetWorktimeTableSimple'
import WidgetWorktimeTableExpanded from '@/components/widgets/WidgetWorktimeTableExpanded'

export default {
  name: 'WidgetWorktime',
  components: { WidgetWorktimeTableSimple, WidgetWorktimeTableExpanded },
  props: {
    userId: { type: Number, required: true },
    users: { type: Array, required: true },
    role: { type: String, required: true }
  },
  data () {
    return {
      model: {
        days: [],
        month: []
      },
      dateObject: new Date(),
      expanded: false,
      loading: true
    }
  },
  computed: {
    from () {
      return moment(this.dateObject).startOf('w').format('YYYY-MM-DD')
    },
    to () {
      return moment(this.dateObject).endOf('w').format('YYYY-MM-DD')
    },
    isNextButtonDisabled () {
      return moment(this.dateObject).add(1, 'w').toDate() > moment().endOf('w').toDate()
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    loadData () {
      this.loading = true
      api.loadWorktime({ from: this.from, to: this.to }).then(data => {
        this.model.days = data.days
        this.model.month = data.month
      }).catch(() => {
        this.$notify({
          title: 'Ошибка',
          message: 'Ошибка загрузки данных',
          type: 'error'
        })
      }).finally(() => {
        this.loading = false
      })
    },
    goNext () {
      this.dateObject = moment(this.dateObject).add(1, 'w').toDate()
      this.loadData()
    },
    goPrev () {
      this.dateObject = moment(this.dateObject).subtract(1, 'w').toDate()
      this.loadData()
    }
  }
}
</script>
