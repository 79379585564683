<template>
  <el-main>
    <h2>Управление проектами</h2>

    <el-form
      inline
      label-position="top"
      size="small"
    >
      <el-form-item>
        <el-checkbox v-model="showArchive">
          Показывать архивные
        </el-checkbox>
      </el-form-item>
    </el-form>

    <el-button
      type="primary"
      @click="showEditPopup = true"
    >
      Добавить проект
    </el-button>

    <table-project
      :project-items="projectItems"
      :show-archive="showArchive"
      :loading="loading"
      @edit="editProject"
    />

    <el-dialog
      :visible="showEditPopup"
      :title="dialogTitle"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <form-project
        :value="selectedProject"
        :check-refresh="showEditPopup"
        :user-items="userItems"
        @cancel="closeEditPopup"
        @save="closeEditPopup"
      />
    </el-dialog>
  </el-main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import FormProject from '@/components/forms/FormProject'
import TableProject from '@/components/tables/TableProject'

export default {
  name: 'IndexProject',
  components: {
    TableProject,
    FormProject
  },
  data () {
    return {
      showEditPopup: false,
      selectedProject: null,
      loading: true
    }
  },
  computed: {
    ...mapGetters('users', ['userItems']),
    ...mapGetters('projects', ['projectItems']),
    dialogTitle () {
      return this.selectedProject !== null ? 'Редактирование проекта' : 'Добавление проекта'
    },
    showArchive: {
      set (archive) {
        this.$router.push({ path: '/projects', query: { ...this.$router.currentRoute.query, archive: archive ? 1 : '' } })
      },
      get () {
        return !!(this.$route.query.archive || false)
      }
    }
  },
  mounted () {
    this.loadUsers().then(() => {
      this.loadProjects().then(() => {
        this.loading = false
      })
    })
  },
  methods: {
    ...mapActions('users', ['loadUsers']),
    ...mapActions('projects', ['loadProjects']),
    editProject (projectId) {
      this.selectedProject = this.projectItems.find(project => project.id === projectId)
      this.showEditPopup = true
    },
    closeEditPopup () {
      this.selectedProject = null
      this.showEditPopup = false
    }
  }
}
</script>
