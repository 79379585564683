<template>
  <div>
    <block-worktime-item
      v-for="item in items"
      :key="item.id"
      :is-manager="isManager"
      :item="item"
      @edit="v => { $emit('edit', v) }"
      @delete="v => { $emit('delete', v) }"
    />
  </div>
</template>
<script>

import BlockWorktimeItem from '@/components/blocks/BlockWorktimeItem'

export default {
  name: 'BlockWorktime',
  components: {
    BlockWorktimeItem
  },
  props: {
    isManager: { type: Boolean, required: true },
    items: { type: Array, required: true }
  },
  methods: {
    edit (item) {
      this.$emit('edit', item)
    }
  }
}
</script>
