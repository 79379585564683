<template>
  <div class="infinite-list-wrapper">
    <div
      v-infinite-scroll="load"
      class="list"
      infinite-scroll-disabled="disabled"
    >
      <block-history-item
        v-for="(item, index) in items"
        :key="index"
        :item="item"
      />
    </div>
    <p v-if="loading">
      Загрузка...
    </p>
    <p v-if="noMore">
      [ Конец истории ]
    </p>
  </div>
</template>
<script>

import api from '@/api/history'
import BlockHistoryItem from '@/components/blocks/BlockHistoryItem'

export default {
  name: 'BlockHistory',
  components: { BlockHistoryItem },
  props: {
    entityCode: { type: String, required: true },
    entityId: { type: Number, required: true },
    userId: { type: Number, default: null }
  },
  data () {
    return {
      items: [],
      loading: true,
      noMore: false,
      page: 1
    }
  },
  computed: {
    disabled () {
      return this.loading || this.noMore
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    load () {
      this.loading = true
      api.load({
        entity_id: this.entityId,
        entity_code: this.entityCode,
        page: this.page,
        user_id: this.userId
      }).then(data => {
        this.items = [...this.items, ...data.items]
        this.noMore = this.items.length >= data.count
        this.page++
      }).catch(() => {
        this.$notify({
          title: 'Ошибка',
          message: 'Невозможно загрузить историю',
          type: 'error'
        })
      }).finally(() => {
        this.loading = false
      })
    },
    reload () {
      this.page = 1
      this.noMore = false
      this.items = []
      this.load()
    }
  }
}
</script>
<style lang="scss" scoped>
.infinite-list-wrapper {
    overflow-y: auto;
    max-height: 400px;
}
</style>
